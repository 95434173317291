import FullRegistration from "@/components/FullRegistration/FullRegistration.vue";

type FullRegistrationInstance = InstanceType<typeof FullRegistration>;

class RegistrationClass {
  requiredFields = {
    patronymic: "",
    city_id: "",
    industry_id: "",
    specialization: "",
    specialization_experience: "",
    gender: "",
    birthday: "",
    email: "",
    password: "",
    confirm_password: "",
    first_name: "",
    last_name: "",
    phone: "",
  };

  optionalFields = {
    partner_promo: "",
    job_title: "",
    employment_type_id: "",
    company: "",
    company_website: "",
    employees_count: "",
    employment_experience: "",
    tax_system: "",
    billing_details: "",
    finance: "",
  };

  getFilledFields() {
    const allFields = { ...this.requiredFields, ...this.optionalFields };
    const filteredFields = {} as typeof allFields;
    (Object.keys(allFields) as Array<keyof typeof allFields>).map((key) => {
      if (allFields[key]) {
        (filteredFields[key] as any) = allFields[key];
      }
    });
    return filteredFields;
  }
}

export class ShortRegistrationClass extends RegistrationClass {
  constructor(data: FullRegistrationInstance) {
    super();
    this.requiredFields.patronymic = data.patronymic;
    this.requiredFields.city_id = data.region;
    this.requiredFields.industry_id = data.industry;
    this.requiredFields.specialization = data.specialization;
    this.requiredFields.specialization_experience =
      data.experienceInSpecialization;
    this.requiredFields.gender = data.gender;
    this.requiredFields.birthday = data.dateOfBirth;

    this.optionalFields.partner_promo = data.promocode;
    this.optionalFields.job_title = data.post;
    this.optionalFields.employment_type_id = data.employmentType;
    this.optionalFields.company = data.company;
    this.optionalFields.company_website = data.site;
    this.optionalFields.employees_count = data.amountEmployees;
    this.optionalFields.employment_experience = data.entrepreneurshipTerm;
    this.optionalFields.tax_system = data.taxationSystem;
    this.optionalFields.billing_details = data.billingDetails;
    this.optionalFields.finance = data.incomeLevel;
  }
}

export class FullRegistrationClass extends ShortRegistrationClass {
  constructor(data: FullRegistrationInstance) {
    super(data);
    this.requiredFields.email = data.email;
    this.requiredFields.password = data.password;
    this.requiredFields.confirm_password = data.passwordConfirm;
    this.requiredFields.first_name = data.name;
    this.requiredFields.last_name = data.secondName;
    this.requiredFields.phone = data.phone;
  }
}
