import { render, staticRenderFns } from "./Materials.vue?vue&type=template&id=77796e36&scoped=true&"
import script from "./Materials.vue?vue&type=script&lang=ts&"
export * from "./Materials.vue?vue&type=script&lang=ts&"
import style0 from "./Materials.scss?vue&type=style&index=0&id=77796e36&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77796e36",
  null
  
)

export default component.exports