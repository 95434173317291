import { State } from "@/store/state";
import { ActionTree } from "vuex";
import { api } from "@/mixins/Common/Api";
import { getSpeakers, getThemes } from "@/classes/Modules";

export default {
  fullRegistration({ state }, payload) {
    const link = state.authed
      ? process.env.VUE_APP_API_COMPLETE_REGISTER
      : process.env.VUE_APP_API_FULL_REGISTER;
    return api.post(link, payload);
  },
  registration(_, user) {
    return api.post(process.env.VUE_APP_API_REGISTER, user);
  },
  getAppInfo() {
    return api.get(process.env.VUE_APP_API_INFO);
  },
  restorePassword(_, email) {
    return api.post(process.env.VUE_APP_API_RESTORE_PASSWORD, {
      email,
    });
  },
  confirmEmail(_, payload) {
    return api.post(
      process.env.VUE_APP_API_CONFIRM_EMAIL,
      {
        id: payload.id,
        key: payload.key,
      },
      {
        cancelErrorHandle: true,
      }
    );
  },
  logoutQuery({ commit }) {
    return api.get(process.env.VUE_APP_API_LOGOUT).then(() => {
      commit("logout");
    });
  },
  orderCoupon(_, payload) {
    return api.post(process.env.VUE_APP_API_ORDER_COUPON, payload);
  },
  login(_, payload) {
    return api.post(process.env.VUE_APP_API_LOGIN, payload);
  },
  resetPassword(_, payload) {
    return api.post(process.env.VUE_APP_API_CHANGE_PASSWORD, payload);
  },
  orderCall({ state }) {
    const orderInfo = {
      email: state.modalOrderCall.email,
      phone: state.modalOrderCall.phone,
      name: state.modalOrderCall.name,
    };
    if (state.modalOrderCall.comment) {
      Object.assign(orderInfo, { comment: state.modalOrderCall.comment });
    }
    return api.post(process.env.VUE_APP_API_ORDER_CALL, orderInfo);
  },
  orderJoin({ state }) {
    const joinInfo = {
      email: state.modalJoin.email,
      phone: state.modalJoin.phone,
      name: state.modalJoin.name,
    };
    return api.post(process.env.VUE_APP_APPLICATION_FOR_MEMBERSHIP, joinInfo);
  },
  sendSpeaker(_, speaker) {
    return api.post(process.env.VUE_APP_API_ADD_SPEAKER, speaker);
  },
  sendTopic(_, theme) {
    return api.post(process.env.VUE_APP_API_ADD_THEME, theme);
  },
  voteSpeaker({ state }, speaker) {
    return api
      .post(process.env.VUE_APP_API_VOTE_SPEAKER, speaker)
      .then((data) => {
        const speakers = getSpeakers(data?.data ?? []);
        state.modules.modulePropose.speakers = speakers;
      });
  },
  voteTopic({ state }, theme) {
    return api.post(process.env.VUE_APP_API_VOTE_THEME, theme).then((data) => {
      const themes = getThemes(data?.data ?? []);
      state.modules.modulePropose.themes = themes;
    });
  },
  getUsers({ state, commit }, cancelErrorHandle = true) {
    return api
      .get(
        process.env.VUE_APP_API_USERS + `?page=${state.usersInfo.actualPage}`,
        {
          cancelErrorHandle,
        }
      )
      .then((data) => {
        commit("writeUserInfo", data.data);
      });
  },
  checkPromo(_, promo) {
    return api.post(process.env.VUE_APP_PROMO_CHECK, promo);
  },
  buyTicket(_, payload) {
    if (payload.tariff_id) {
      return api.post(process.env.VUE_APP_BUY_TICKET, payload);
    } else {
      return api.post(process.env.VUE_APP_BUY_OPTION, payload);
    }
  },
  buyTicketOrganization(_, payload) {
    if (payload.tariff_id) {
      return api.post(process.env.VUE_APP_BUY_TICKET_ORGANIZATION, payload);
    } else {
      return api.post(process.env.VUE_APP_BUY_OPTION_ORGANIZATION, payload);
    }
  },
  eventRegistration(_, user) {
    return api.post(process.env.VUE_APP_EVENT_REGISTRATION, user);
  },
  subscription(_, email) {
    return api.post(process.env.VUE_APP_API_SUBSRIPTION, email);
  },
  checkAccreditation({ commit }, params) {
    return api
      .post(process.env.VUE_APP_CHECK_ACCREDITATION, params)
      .then((data) => {
        commit("writeAccreditations", data.data);
      });
  },
  getNews({ commit }, pageNumber: number) {
    return api
      .get(process.env.VUE_APP_GET_NEWS + `?page=${pageNumber}`)
      .then((data) => {
        commit("writeNewsList", data.data);
      });
  },
  getNewsById({ commit }, id: string) {
    return api.get(process.env.VUE_APP_GET_NEWS + `?id=${id}`).then((data) => {
      return data.data;
    });
  },
} as ActionTree<State, State>;
