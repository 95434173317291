
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { inputValidate } from "@/types/types";

export default Vue.extend({
  name: "JoinModal",
  data() {
    return {
      joinRequest: false,
      errors: {
        phone: "",
        email: "",
        name: "",
      },
    };
  },
  props: {
    showModal: {
      type: Boolean,
    },
  },
  computed: {
    ...mapState(["modalJoin"]),
    formJoin(): inputValidate {
      return this.$refs.formJoin as inputValidate;
    },
  },
  methods: {
    ...mapActions(["orderJoin"]),
    ...mapMutations(["changeModalJoin"]),
    clearError() {
      this.errors = {
        phone: "",
        email: "",
        name: "",
      };
    },
    async joinHandler() {
      const valid = this.formJoin.validate();
      if (valid) {
        try {
          this.joinRequest = true;
          await this.orderJoin();
          const emptyData = {
            opened: false,
            email: "",
            phone: "",
            name: "",
          };
          this.changeModalJoin(emptyData);
          this.clearError();

          this.formJoin.resetValidation();
          this.openAlert("Ваша заявка отправлена!");
          this.$emit("close");
        } catch (error) {
          this.clearError();
          this.errors = this.validationErrorsHandler(error);
        } finally {
          this.joinRequest = false;
        }
      }
    },
  },
});
