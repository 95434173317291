
import Vue from "vue";
import { mapState } from "vuex";

export type tableItem = {
  id: string;
  text: string;
  author: string;
  voted: boolean;
  votes: string;
  discount: number;
};
const rowsShowing = 10;

export default Vue.extend({
  name: "VoteTable",
  props: {
    tableItems: {
      type: Array as () => tableItem[],
    },
    voting: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Тема",
    },
    link: {
      type: String,
    },
  },
  data() {
    return {
      rowsShowing: rowsShowing,
      sendingRatingId: [] as string[],
    };
  },
  computed: {
    ...mapState(["authed"]),
    rows(): tableItem[] {
      return this.tableItems?.slice(0, this.rowsShowing) ?? [];
    },
  },
  methods: {
    async sendRating(item: tableItem) {
      if (!this.authed) {
        this.$emit("vote");
        return;
      }
      const action = "vote";
      this.sendingRatingId.push(item.id);
      const data = {
        id: item.id,
        action,
      };
      this.$emit("vote", data, () => {
        this.sendingRatingId = this.sendingRatingId.filter(
          (el) => el !== item.id
        );
      });
    },
    rowsShowingAdd() {
      this.rowsShowing += rowsShowing;
    },
  },
});
