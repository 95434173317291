
import Vue from "vue";
import Title from "@/components/CommonComponents/Title/Title.vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "LocationComponent",
  components: { Title },
  data() {
    return {};
  },
  computed: {
    ...mapState(["modules"]),
    location() {
      return this.modules.moduleLocation;
    },
  },
});
