
import Vue from "vue";
import Certification from "./Certification/Certification.vue";
import Materials from "./Materials/Materials.vue";
import AccreditedList from "./AccreditedList/AccreditedList.vue";
import { mapState } from "vuex";
import {
  Accreditation,
  CertificationType,
  MaterialList,
} from "@/store/types/stateTypes";

export default Vue.extend({
  name: "CertificatesComponent",
  components: { Certification, Materials, AccreditedList },
  computed: {
    ...mapState(["modules"]),
    certification(): CertificationType {
      return this.modules.moduleCertification.certification;
    },
    materialList(): MaterialList[] {
      return this.modules.moduleCertification.materialList.filter(
        (item: MaterialList) => item.materials.length > 0
      );
    },
    accreditedList(): Accreditation[] {
      return this.modules.moduleCertification.accreditations;
    },
  },
});
