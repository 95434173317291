import * as stateTypes from "@/store/types/stateTypes";
import { ModalJoinType, team, VideoModule } from "@/store/types/stateTypes";

export const state = {
  /** Текущая ширина экрана */
  window: null as number | null,
  authed: false,
  fullAuthed: false,
  isEmailVerified: false,
  alertModal: {
    show: false,
    text: "",
  },
  user: {
    id: 0,
    accessBack: false,
    firstName: "",
    lastName: "",
    patronymic: "",
  },
  modalPersonalCabinet: {
    opened: false,
    email: "",
    password: "",
  },
  modalOrderCall: {
    opened: false,
    email: "",
    phone: "",
    name: "",
    comment: "",
  },
  modalJoin: {
    opened: false,
    email: "",
    phone: "",
    name: "",
  } as Record<keyof ModalJoinType, string | boolean>,
  baseUrl: "",
  industries: [] as stateTypes.EnumerableObject[],
  employmentTypes: [] as stateTypes.EnumerableObject[],
  genders: [] as stateTypes.EnumerableObject[],
  cities: [] as stateTypes.EnumerableObject[],
  usersInfo: {
    count: 0,
    actualPage: 1,
    allUsersLoaded: true,
    users: [] as stateTypes.infoUser[],
  },
  modules: {
    customCss: { style: "" },
    navMenu: {
      headerLogo: "",
      headerText: "",
      menu: [],
    } as stateTypes.NavMenuType,
    moduleHeader: {
      active: false,
      backgroundColor: "",
      backgroundImage: "",
      date: "",
      location: "",
      locationLink: "",
      logo: "",
      organizer: "",
      organizerLink: "",
      slogan: "",
      bigText: "",
      title: "",
    },
    moduleFooter: {
      backgroundColor: "",
      email: "",
      logo: "",
      offer: "",
      personalData: "",
      phone: "",
      requisites: "",
      text: "",
      title: "",
    },
    moduleParticipants: {
      active: false,
      participants: [] as stateTypes.Participants[],
    },
    moduleVideo: {
      title: "",
      list: [],
    } as VideoModule,
    moduleMotivator: {
      active: false,
      backgroundColorFirst: "",
      backgroundColorSecond: "",
      backgroundImage: "",
      subtitleFirst: "",
      subtitleSecond: "",
      subtitleSecondColor: "",
      title: "",
      titleColor: "",
      invitation: {
        active: false,
        title: "",
        link: "",
      },
    },
    moduleSocial: {
      active: false,
      socials: {
        title: "",
        socials: [] as stateTypes.Social[],
      },
    },
    moduleProgram: {
      active: true,
      title: "",
      programs: [] as stateTypes.Program[],
      download: {
        active: false,
        title: "",
        link: "",
      },
      categories: [] as stateTypes.EnumerableObjectWithID[],
      ranges: [] as stateTypes.RangesType[],
      rooms: [] as stateTypes.EnumerableObjectWithID[],
    },
    modulePropose: {
      active: false,
      voteEnd: 0,
      voteStart: 0,
      proposeEnd: 0,
      proposeStart: 0,
      speakerFirst: 0,
      speakerSecond: 0,
      speakerThird: 0,
      themeFirst: 0,
      themeSecond: 0,
      themeThird: 0,
      title: "",
      speakers: [] as stateTypes.Speaker[],
      themes: [] as stateTypes.Theme[],
    },
    moduleCoupons: {
      active: false,
      title: "",
      coupons: [] as stateTypes.Coupon[],
    },
    moduleParticipationReason: {
      active: false,
      title: "",
    },
    moduleParticipantList: {
      active: false,
      title: "",
    },
    moduleParticipantListCustom: {
      active: false,
      title: "",
      backgroundImage: "",
      modal: {
        fieldRegistration: "",
        editableField: {} as stateTypes.EditableField,
      },
      list: {
        count: "",
        users: [] as stateTypes.EditableField[],
      },
    },
    moduleAdvertisement: {
      active: false,
      first: [] as stateTypes.Advertisement[],
      second: [] as stateTypes.Advertisement[],
    },
    moduleSponsor: {
      active: false,
      title: "",
      sponsors: [] as stateTypes.Sponsor[],
      sponsorLevels: [] as string[],
    },
    moduleTicket: {
      active: false,
      title: "",
      rows: [] as stateTypes.Row[],
      tariffs: [] as stateTypes.Tariff[],
      boughtTicketId: 0,
      additional: [] as stateTypes.AdditionalTicket[],
    },
    moduleFullRegistration: {
      active: false,
      title: "",
    },
    moduleRegistration: {
      active: false,
      title: "",
    },
    moduleEmailConfirm: {
      body: "",
    },
    moduleAbout: {
      about_text: "",
      about_title: "",
      about_background_image: "",
      mission_image: "",
      mission_link: "",
      mission_text: "",
      mission_title: "",
    },
    moduleNews: {
      news: [] as stateTypes.NewsCard[],
      title: "",
      pageSize: 0,
      count: 0,
    },
    moduleCalendar: {
      events: [] as stateTypes.EventCalendar[],
      title: "",
      buttons: {
        registrationButton: {
          text: "Участвовать",
        },
      },
    },
    moduleHistory: {
      title: "",
      description: "",
      history: [] as stateTypes.History[],
    },
    moduleTeam: {
      title: "",
      members: {},
    } as team,
    moduleDocument: {
      title: "",
      documentList: [] as stateTypes.DocumentList[],
    },
    moduleContact: {
      title: "",
      contacts: [] as stateTypes.Contact[],
    },
    moduleLocation: {
      title: "",
      img: "",
      link: "",
      map: "",
      text: "",
      nameLink: "",
    },
    moduleRewards: {
      title: "",
      rewardList: [] as stateTypes.Reward[][],
    },
    modulePartnerProgram: {
      title: "",
      categories: {} as { [key: number]: string },
      partners: [] as stateTypes.Partner[],
    },
    moduleCertification: {
      accreditations: [] as stateTypes.Accreditation[],
      certification: {
        id: "",
        image: "",
        items: [],
        text: "",
      } as stateTypes.CertificationType,
      materialList: [] as stateTypes.MaterialList[],
      titles: {
        accreditationsTitle: "",
        certificationTitle: "",
        materialsTitle: "",
      },
      buttons: {
        certificationButton: {
          module: "",
          text: "",
        },
      },
    },
    moduleRetrospective: {
      gallery: "",
      massMedia: [] as stateTypes.MassMedia[],
      reviews: [] as stateTypes.Review[],
      numbers: [] as stateTypes.ListNumbersType,
      videos: [] as stateTypes.ListVideosType,
      titles: {
        massMediaTitle: "",
        retrospectiveTitle: "",
        reviewTitle: "",
      },
    },
  },
  sortedModules: [] as string[],
  event: {
    id: null as number | null,
  },
};

export type State = typeof state;
export type Modules = State["modules"];
