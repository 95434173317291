
import Vue from "vue";
import { Program } from "@/store/types/stateTypes";
import VideoLink from "@/components/CommonComponents/VideoLink/VideoLink.vue";

export default Vue.extend({
  name: "ProgramCard",
  components: { VideoLink },
  data() {
    return {
      breakModal: false,
    };
  },
  props: {
    programItem: {
      type: Object,
    },
    categoryActive: {
      type: String,
    },
    isBreak: {
      type: Boolean,
    },
    programBreak: {
      type: Object as () => Program,
    },
  },
  computed: {
    program(): Program {
      return this.programItem;
    },
  },
});
