import { render, staticRenderFns } from "./ShareTo.vue?vue&type=template&id=ce0f2472&scoped=true&"
import script from "./ShareTo.vue?vue&type=script&lang=ts&"
export * from "./ShareTo.vue?vue&type=script&lang=ts&"
import style0 from "./ShareTo.scss?vue&type=style&index=0&id=ce0f2472&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce0f2472",
  null
  
)

export default component.exports