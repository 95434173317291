
import Vue from "vue";
import { mapState } from "vuex";
import Title from "@/components/CommonComponents/Title/Title.vue";
import VideoLink from "@/components/CommonComponents/VideoLink/VideoLink.vue";
import { History, HistoryList } from "@/store/types/stateTypes";

export default Vue.extend({
  name: "HistoryOfCreation",
  components: { Title, VideoLink },
  data() {
    return {
      activeYear: 0,
    };
  },
  computed: {
    ...mapState(["modules"]),
    title(): string {
      return this.modules.moduleHistory.title;
    },
    description(): string {
      return this.modules.moduleHistory.description;
    },
    historyList(): History[] {
      return this.modules.moduleHistory.history;
    },
    currentHistoryList(): HistoryList[] {
      const list = this.historyList.find(
        (el: History) => el.year === this.activeYear
      )?.historyList;
      return list ? list : [];
    },
  },
  mounted() {
    this.activeYear = this.historyList[0].year;
  },
});
