
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import NewsCard from "./NewsCard/NewsCard.vue";
import Title from "@/components/CommonComponents/Title/Title.vue";
import * as stateTypes from "@/store/types/stateTypes";

export default Vue.extend({
  name: "NewsComponent",
  components: { NewsCard, Title },
  data() {
    return {
      page: 1,
      loadNews: false,
    };
  },
  computed: {
    ...mapState(["modules"]),
    news(): stateTypes.NewsCard[] {
      return this.modules.moduleNews.news;
    },
    title(): string {
      return this.modules.moduleNews.title;
    },
    showPagination(): boolean {
      return this.modules.moduleNews.count > this.modules.moduleNews.pageSize;
    },
    pageCount(): number {
      return Math.ceil(
        this.modules.moduleNews.count / this.modules.moduleNews.pageSize
      );
    },
  },
  methods: {
    ...mapActions(["getNews"]),
    updatePage(page: number) {
      if (page === this.page) {
        return;
      }
      this.loadNews = true;
      this.page = page;
      this.getNews(page).finally(() => {
        this.loadNews = false;
      });
    },
  },
});
