import Vue from "vue";
import { smoothToElems } from "@/types";
import { mapMutations, mapState } from "vuex";
import axios from "axios";

export default Vue.extend({
  computed: {
    ...mapState(["baseUrl"]),
  },
  methods: {
    ...mapMutations(["openAlert"]),
    async smoothTo(elemId: smoothToElems) {
      if (this.$route.name !== "Main") {
        await this.$router.push({ name: "Main" });
      }
      const myEl = document.getElementById(elemId);

      if (myEl) {
        this.$smoothScroll({
          scrollTo: myEl as Element,
        });
      }
    },
    validationErrorsHandler(error: unknown) {
      const errors = {} as any;
      if (axios.isAxiosError(error)) {
        const data = error.response?.data;
        const errorValidation = data?.name === "Errors of validation";
        if (errorValidation) {
          return Object.keys(data.fail).map((key) => {
            errors[key] = data.fail[key];
          });
        }
      }
      return errors;
    },
    changeHash(hash: string, queryParams: { [key: string]: string } = {}) {
      this.$router.push({
        hash: `#${hash}`,
        query: { ...this.$route.query, ...queryParams },
      });
    },
  },
});
