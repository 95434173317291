
import Vue from "vue";

export default Vue.extend({
  name: "HexagonComponent",
  props: {
    text: {
      type: String,
      default: "",
    },
    firstLine: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    last: {
      type: Boolean,
      default: false,
    },
  },
});
