
import Vue from "vue";
import ProposeInfo from "@/components/ProposeInfo/ProposeInfo.vue";
import Vote from "@/components/Vote/Vote.vue";

export default Vue.extend({
  name: "ProposeComponent",
  components: {
    ProposeInfo,
    Vote,
  },
});
