
import Vue from "vue";

export default Vue.extend({
  name: "DatePicker",
  props: {
    date: {
      type: String,
    },
    label: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    max: {
      type: String,
    },
  },
  data() {
    return {
      opened: false,
    };
  },
  computed: {
    enabledRules() {
      if (this.required) {
        const required = (v: string) => v !== "" || "Обязательное поле";
        return [required];
      }
      return [];
    },
  },

  methods: {
    changeDate(value: string) {
      this.$emit("input", value);
      this.opened = false;
    },
  },
});
