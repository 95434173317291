import store from "@/store/index";
import * as moduleTypes from "./ModulesTypes";
import * as stateTypes from "@/store/types/stateTypes";
import { sponsor } from "./ModulesTypes";

export interface ModulesFromBack {
  customCss?: moduleTypes.customCssModule;
  navMenu?: moduleTypes.navMenu;
  header?: moduleTypes.headerModuleBack;
  footer?: moduleTypes.footerModuleBack;
  participants?: moduleTypes.moduleParticipantsBack[];
  video?: moduleTypes.moduleVideoBack;
  motivator?: moduleTypes.moduleMotivatorBack;
  socials?: moduleTypes.moduleSocialBack;
  program?: moduleTypes.moduleProgramBack;
  propose?: moduleTypes.proposeType;
  coupons?: moduleTypes.couponsModuleBack;
  participationReason?: moduleTypes.participationReasonBack;
  participantList?: moduleTypes.participantListBack;
  participantListCustom?: moduleTypes.participantListCustomBack;
  advertisement?: moduleTypes.advertisementModuleBack;
  sponsor?: moduleTypes.sponsorBack;
  sponsor_levels?: moduleTypes.sponsorLevel;
  ticket?: moduleTypes.ticketModuleBack;
  twoLevelRegistration?: moduleTypes.fullRegistration;
  registration?: moduleTypes.registration;
  emailConfirm?: moduleTypes.moduleEmailConfirm;
  about?: moduleTypes.moduleAbout;
  news?: moduleTypes.moduleNews;
  calendar?: moduleTypes.moduleCalendar;
  history?: moduleTypes.moduleHistory;
  teamMember?: moduleTypes.moduleTeam;
  document?: moduleTypes.moduleDocuments;
  contact?: moduleTypes.moduleContact;
  rewards?: moduleTypes.moduleRewards;
  partnerProgram?: moduleTypes.modulePartnerProgram;
  certification?: moduleTypes.moduleCertification;
  retrospective?: moduleTypes.moduleRetrospective;
}

export class ModulesClass {
  customCss = store.state.modules.customCss;
  navMenu = store.state.modules.navMenu;
  moduleHeader = store.state.modules.moduleHeader;
  moduleFooter = store.state.modules.moduleFooter;
  moduleParticipants = store.state.modules.moduleParticipants;
  moduleVideo = store.state.modules.moduleVideo;
  moduleMotivator = store.state.modules.moduleMotivator;
  moduleSocial = store.state.modules.moduleSocial;
  moduleProgram = store.state.modules.moduleProgram;
  modulePropose = store.state.modules.modulePropose;
  moduleCoupons = store.state.modules.moduleCoupons;
  moduleParticipationReason = store.state.modules.moduleParticipationReason;
  moduleParticipantList = store.state.modules.moduleParticipantList;
  moduleParticipantListCustom = store.state.modules.moduleParticipantListCustom;
  moduleAdvertisement = store.state.modules.moduleAdvertisement;
  moduleSponsor = store.state.modules.moduleSponsor;
  moduleTicket = store.state.modules.moduleTicket;
  moduleFullRegistration = store.state.modules.moduleFullRegistration;
  moduleRegistration = store.state.modules.moduleRegistration;
  moduleEmailConfirm = store.state.modules.moduleEmailConfirm;
  moduleAbout = store.state.modules.moduleAbout;
  moduleNews = store.state.modules.moduleNews;
  moduleHistory = store.state.modules.moduleHistory;
  moduleCalendar = store.state.modules.moduleCalendar;
  moduleTeam = store.state.modules.moduleTeam;
  moduleDocument = store.state.modules.moduleDocument;
  moduleContact = store.state.modules.moduleContact;
  moduleLocation = store.state.modules.moduleLocation;
  moduleRewards = store.state.modules.moduleRewards;
  modulePartnerProgram = store.state.modules.modulePartnerProgram;
  moduleCertification = store.state.modules.moduleCertification;
  moduleRetrospective = store.state.modules.moduleRetrospective;

  constructor(modules: ModulesFromBack) {
    if (modules.navMenu) {
      this.navMenu = getNavMenu(modules.navMenu);
    }
    if (modules.header?.active) {
      this.moduleHeader = getHeader(modules.header);
    }
    if (modules.footer) {
      this.moduleFooter = getFooter(modules.footer);
    }
    if (modules.participants?.length) {
      this.moduleParticipants = {
        active: true,
        participants: getParticipants(modules.participants),
      };
    }
    if (modules.video) {
      this.moduleVideo = getVideos(modules.video);
    }
    if (modules.motivator?.active) {
      this.moduleMotivator = getMotivator(modules.motivator);
    }
    if (modules.socials?.socials?.length) {
      this.moduleSocial = {
        active: true,
        socials: modules.socials,
      };
    }
    if (modules.program?.program?.length) {
      this.moduleProgram = {
        active: true,
        title: modules.program.title,
        programs: getProgram(modules.program.program),
        download: modules.program.download,
        categories: getEnumerableObjectWithID(modules.program.categories),
        ranges: modules.program.ranges,
        rooms: getEnumerableObjectWithID(modules.program.rooms),
      };
    }
    if (modules.propose?.module.active) {
      this.modulePropose = getPropose(modules.propose);
    }
    if (modules.coupons) {
      this.moduleCoupons = getCoupons(modules.coupons);
    }
    if (modules.participationReason?.active) {
      this.moduleParticipationReason = {
        active: !!modules.participationReason.active,
        title: modules.participationReason.title,
      };
    }
    if (modules.participantList?.active) {
      this.moduleParticipantList = {
        active: !!modules.participantList.active,
        title: modules.participantList.title,
      };
    }

    if (modules.participantListCustom?.active) {
      this.moduleParticipantListCustom = {
        active: !!modules.participantListCustom.active,
        title: modules.participantListCustom.title,
        backgroundImage: modules.participantListCustom.background_image
          ? store.state.baseUrl + modules.participantListCustom.background_image
          : "",
        modal: {
          fieldRegistration:
            modules.participantListCustom.modal.field_registration,
          editableField: modules.participantListCustom.modal.editable_fields,
        },
        list: {
          ...modules.participantListCustom.list,
        },
      };
    }
    if (modules.advertisement && Object.keys(modules.advertisement).length) {
      const first = modules.advertisement[1] ?? [];
      const second = modules.advertisement[2] ?? [];
      this.moduleAdvertisement = {
        active: true,
        first,
        second,
      };
    }
    if (modules.sponsor && Object.keys(modules.sponsor).length) {
      const { title, ...rest } = modules.sponsor;
      this.moduleSponsor = {
        active: true,
        title: title,
        sponsors: getSponsors(rest),
        sponsorLevels: getSponsorsTitles(modules.sponsor_levels ?? {}),
      };
    }
    if (modules.ticket?.module.active) {
      this.moduleTicket = getTicket(modules.ticket);
    }
    if (modules.twoLevelRegistration?.active) {
      this.moduleFullRegistration = {
        active: !!modules.twoLevelRegistration,
        title: modules.twoLevelRegistration.title,
      };
    }
    if (modules.registration?.active) {
      this.moduleRegistration = {
        active: !!modules.registration.active,
        title: modules.registration.title,
      };
    }
    if (modules.customCss?.style) {
      this.customCss = modules.customCss;
    }
    if (modules.emailConfirm?.body) {
      this.moduleEmailConfirm = {
        body: modules.emailConfirm.body,
      };
    }
    if (modules.about) {
      this.moduleAbout = getAboutUs(modules.about);
    }
    if (modules.news) {
      this.moduleNews = getNews(modules.news);
    }
    if (modules.calendar) {
      this.moduleCalendar = getEventsCalendar(modules.calendar);
    }
    if (modules.history) {
      this.moduleHistory = getHistory(modules.history);
    }
    if (modules.teamMember) {
      this.moduleTeam = getTeam(modules.teamMember);
    }
    if (modules.document) {
      this.moduleDocument = {
        title: modules.document.title,
        documentList: getDocumentList(modules.document),
      };
    }
    if (modules.contact?.common) {
      this.moduleContact = {
        title: modules.contact.contactsTitle,
        contacts: getContacts(modules.contact),
      };
      this.moduleLocation = {
        title: modules.contact.commonTitle,
        img: modules.contact.common.image
          ? store.state.baseUrl + modules.contact.common.image
          : "",
        link: modules.contact.common.link,
        map: modules.contact.common.map,
        text: modules.contact.common.text,
        nameLink: modules.contact.common.link_text,
      };
    }
    if (modules.rewards) {
      this.moduleRewards = {
        title: modules.rewards.title,
        rewardList: getRewardList(modules.rewards),
      };
    }
    if (modules.partnerProgram) {
      this.modulePartnerProgram = getPartnerPrograms(modules.partnerProgram);
    }
    if (modules.certification?.certification) {
      this.moduleCertification = {
        materialList: getMaterialList(modules.certification),
        certification: getCertification(modules.certification.certification),
        accreditations: [],
        titles: modules.certification.titles,
        buttons: modules.certification.buttons,
      };
    }

    if (modules.retrospective) {
      this.moduleRetrospective = {
        gallery: modules.retrospective.gallery,
        reviews: modules.retrospective.reviews.map((el) => {
          return {
            content: el.content,
            description: el.description,
            id: el.id,
            jobTitle: el.job_title,
            name: el.name,
            type: el.type,
            image: el.image ? store.state.baseUrl + el.image : "",
          };
        }),
        massMedia: [...modules.retrospective.mass_media],
        numbers: modules.retrospective.numbers.map((el) => {
          return { number: +el.value, text: el.description };
        }),
        videos: modules.retrospective.videos.map((el) => {
          return {
            link: el.link,
            videoPoster: el.video_poster
              ? store.state.baseUrl + el.video_poster
              : "",
            videoTitle: el.video_title,
          };
        }),
        titles: { ...modules.retrospective.titles },
      };
    }
  }
}

const getNavMenu = (navMenu: moduleTypes.navMenu) => {
  return {
    headerLogo: navMenu.header_logo
      ? store.state.baseUrl + navMenu.header_logo
      : "",
    headerText: navMenu.header_text,
    menu: navMenu.items,
  };
};

const getHeader = (header: moduleTypes.headerModuleBack) => {
  return {
    active: !!header.active,
    backgroundColor: header.background_color,
    backgroundImage: header.background_image,
    date: header.date,
    location: header.location,
    locationLink: header.location_link,
    logo: header.logo,
    organizer: header.organizer,
    organizerLink: header.organizer_link,
    slogan: header.slogan,
    bigText: header.big_text,
    title: header.title,
  };
};

const getFooter = (footer: moduleTypes.footerModuleBack) => {
  return {
    backgroundColor: footer.background_color,
    email: footer.email,
    logo: footer.logo.length ? store.state.baseUrl + footer.logo : "",
    offer: footer.offer,
    personalData: footer.personal_data,
    phone: footer.phone,
    requisites: footer.requisites,
    text: footer.text,
    title: footer.title,
  };
};

const getMotivator = (motivator: moduleTypes.moduleMotivatorBack) => {
  return {
    active: !!motivator.active,
    backgroundColorFirst: motivator.background_color_1,
    backgroundColorSecond: motivator.background_color_2,
    backgroundImage: motivator.background_image,
    subtitleFirst: motivator.subtitle_1,
    subtitleSecond: motivator.subtitle_2,
    subtitleSecondColor: motivator.subtitle_2_color,
    title: motivator.title,
    titleColor: motivator.title_color,
    invitation: motivator.invitation,
  };
};

const getParticipants = (
  participants: moduleTypes.moduleParticipantsBack[]
) => {
  return participants.map((participant) => {
    const description = participant.description.split("\r\n");
    return {
      ...participant,
      image: store.state.baseUrl + participant.image,
      description,
    };
  });
};

const getVideos = (videoModule: moduleTypes.moduleVideoBack) => {
  return {
    title: videoModule.title,
    list: videoModule.list.map((video) => {
      return {
        link: video.link,
        poster: video.poster ? store.state.baseUrl + video.poster : "",
      };
    }),
  };
};

const getProgram = (program: moduleTypes.ProgramBack[]) => {
  return program.map((el) => {
    const presentation = el.attaches.find((attach) => attach.type === 1);
    return {
      ...el,
      photo: el.photo.length ? store.state.baseUrl + el.photo : "",
      video: el.attaches.find((attach) => attach.type === 3),
      bonus: el.attaches.find((attach) => attach.type === 2),
      presentation: presentation
        ? {
            ...presentation,
            file_name: store.state.baseUrl + presentation.file_name,
          }
        : undefined,
      details: getDetails(el.details),
    };
  });
};

const getDetails = (details: moduleTypes.Details) => {
  if (details) {
    return {
      ...details,
      image: details.image.length ? store.state.baseUrl + details.image : "",
    };
  }
  return details;
};

const getEnumerableObjectWithID = (category: { [key: string]: string }) => {
  const categories = [] as { id: string; name: string }[];
  Object.entries(category).forEach((category) => {
    categories.push({
      id: category[0],
      name: category[1],
    });
  });
  return categories;
};

export const getSpeakers = (speakers: moduleTypes.proposeType["speakers"]) => {
  return speakers.map((speaker) => ({
    comment: speaker.comment,
    fullName: speaker.full_name,
    id: speaker.id,
    name: speaker.name,
    voted: speaker.voted,
    votes: speaker.votes,
  }));
};

export const getThemes = (themes: moduleTypes.proposeType["themes"]) => {
  return themes.map((theme) => ({
    description: theme.description,
    fullName: theme.full_name,
    id: theme.id,
    title: theme.title,
    voted: theme.voted,
    votes: theme.votes,
  }));
};

const getPropose = (propose: moduleTypes.proposeType) => {
  const speakers = getSpeakers(propose.speakers);
  const themes = getThemes(propose.themes);
  return {
    active: !!propose.module.active,
    voteEnd: propose.module.vote_end * 1000,
    voteStart: propose.module.vote_start * 1000,
    proposeEnd: propose.module.propose_end * 1000,
    proposeStart: propose.module.propose_start * 1000,
    speakerFirst: propose.module.speaker_first,
    speakerSecond: propose.module.speaker_second,
    speakerThird: propose.module.speaker_third,
    themeFirst: propose.module.theme_first,
    themeSecond: propose.module.theme_second,
    themeThird: propose.module.theme_third,
    title: propose.module.title,
    speakers,
    themes,
  };
};

const getCoupons = (coupons: moduleTypes.couponsModuleBack) => {
  const couponFiltered = coupons.list.map((coupon) => ({
    id: coupon.id,
    congressId: coupon.congress_id,
    count: coupon.count,
    discountText: coupon.discount_text,
    getText:
      coupon.get_text[0].toUpperCase() + coupon.get_text.toLowerCase().slice(1),
  }));
  return {
    active: true,
    title: coupons.title,
    coupons: couponFiltered,
  };
};

const getSponsors = (sponsors: { [key: number]: sponsor[] }) => {
  let sponsorsFiltered = Object.values(sponsors).reduce(
    (acc, sponsor) => acc.concat(sponsor),
    []
  );
  sponsorsFiltered = sponsorsFiltered.map((sponsor) => {
    return { ...sponsor, logo: store.state.baseUrl + sponsor.logo };
  });
  return sponsorsFiltered;
};

const getSponsorsTitles = (titles: moduleTypes.sponsorLevel) => {
  return Object.values(titles);
};

const getTicket = (ticket: moduleTypes.ticketModuleBack) => {
  const rows = ticket.rows.map((row) => {
    return {
      ...row,
      hidden: !!row.hidden,
    };
  });
  const tariffs = ticket.tariff.map((tariff) => {
    const params = Object.values(tariff.params).map((param) => {
      return {
        tariffId: param.tariff_id,
        rowId: param.row_id,
        value: param.value,
      };
    });
    return {
      ...tariff,
      params: { ...params },
    };
  });

  return {
    additional: ticket.additional,
    active: true,
    title: ticket.module.title,
    rows,
    tariffs,
    boughtTicketId: ticket.boughtTicketId,
  };
};

const getTeam = (team: moduleTypes.moduleTeam) => {
  const members = team.members.map((el) => {
    return {
      ...el,
      photo: store.state.baseUrl + el.photo,
    };
  });
  return {
    title: team.title,
    members: [
      { ...members.filter((el) => el.category === "1") },
      { ...members.filter((el) => el.category === "2") },
      { ...members.filter((el) => el.category === "3") },
    ],
  };
};

const getAboutUs = (about: moduleTypes.moduleAbout) => {
  return {
    ...about,
    mission_image: about.mission_image.length
      ? store.state.baseUrl + about.mission_image
      : "",
    about_background_image: about.about_background_image
      ? store.state.baseUrl + about.about_background_image
      : "",
  };
};

const getNews = (news: moduleTypes.moduleNews) => {
  return {
    title: news.title,
    pageSize: news.pageSize,
    count: news.count,
    news: news.news.map((cardNews) => {
      return {
        ...cardNews,
        image: cardNews.image.length
          ? store.state.baseUrl + cardNews.image
          : "",
        created_at: new Date(+cardNews.created_at * 1000).toLocaleString("ru", {
          month: "long",
          day: "numeric",
          year: "numeric",
        }),
      };
    }),
  };
};
const getEventsCalendar = (eventsCalendar: moduleTypes.moduleCalendar) => {
  const events = eventsCalendar.calendar.map((event) => {
    return {
      date: new Date(+event.event_start_time * 1000).toLocaleString("ru", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }),
      startTime: new Date(+event.event_start_time * 1000).toLocaleString("ru", {
        hour: "numeric",
        minute: "numeric",
      }),
      endTime: new Date(+event.event_end_time * 1000).toLocaleString("ru", {
        hour: "numeric",
        minute: "numeric",
      }),
      eventStartTime: event.event_start_time,
      enableRegistration: event.enable_registration,
      id: event.id,
      image: event.image.length ? store.state.baseUrl + event.image : "",
      video: event.video,
      text: event.text,
      title: event.title,
      companyIsRequired: event.company_is_required,
    };
  });
  return {
    title: eventsCalendar.title,
    events: events.sort((a, b) => +a.eventStartTime - +b.eventStartTime),
    buttons: {
      registrationButton: {
        text: eventsCalendar.buttons.registrationButton.text,
      },
    },
  };
};

const getHistory = (history: moduleTypes.moduleHistory) => {
  const years = [] as number[];
  const historyList = [] as stateTypes.History[];
  history.history.forEach((el) => {
    const year = new Date(+el.event_start_time * 1000).getFullYear();
    const historyItem = {
      id: el.id,
      image: el.image.length ? store.state.baseUrl + el.image : "",
      text: el.text,
      title: el.title,
      video: el.video,
    };
    if (!years.includes(year)) {
      years.push(year);
      historyList.push({
        year: year,
        historyList: [historyItem],
      });
    } else {
      historyList.forEach((history) => {
        if (history.year === year) {
          history.historyList.push(historyItem);
        }
      });
    }
  });
  return {
    title: history.title,
    description: history.description,
    history: historyList,
  };
};

const getDocumentList = (documents: moduleTypes.moduleDocuments) => {
  const documentList = [] as stateTypes.DocumentList[];
  Object.entries(documents.categories).forEach((category) => {
    const list = {
      title: category[1],
      documents: documents.documents
        .filter((document) => document.category === category[0])
        .map((document) => {
          return { ...document, path: store.state.baseUrl + document.path };
        }),
    };
    documentList.push(list);
  });
  return documentList;
};

const getContacts = (contacts: moduleTypes.moduleContact) => {
  return contacts.contacts.map((el) => {
    return { ...el, photo: el.photo ? store.state.baseUrl + el.photo : "" };
  });
};

const getRewardList = (rewards: moduleTypes.moduleRewards) => {
  const rewardsList = [] as stateTypes.Reward[][];
  Object.entries(rewards.categories).forEach((category) => {
    rewardsList.push(
      rewards.rewards
        .filter((reward) => reward.category_id === category[0])
        .map((el) => {
          return {
            ...el,
            cert_image: el.cert_image
              ? store.state.baseUrl + el.cert_image
              : "",
            photo: el.photo ? store.state.baseUrl + el.photo : "",
          };
        })
    );
  });
  return rewardsList;
};

const getPartnerPrograms = (
  partnerProgram: moduleTypes.modulePartnerProgram
) => {
  return {
    ...partnerProgram,
    partners: partnerProgram.partners.map((program) => {
      return {
        ...program,
        image: program.image ? store.state.baseUrl + program.image : "",
      };
    }),
  };
};

const getCertification = (certification: stateTypes.CertificationType) => {
  return {
    ...certification,
    image: certification.image ? store.state.baseUrl + certification.image : "",
  };
};

const getMaterialList = (certification: moduleTypes.moduleCertification) => {
  const materialList = [] as stateTypes.MaterialList[];
  Object.entries(certification.material_types).forEach((type) => {
    const list = {
      title: type[1],
      materials: certification.materials
        .filter((material) => material.type === type[0])
        .map((material) => {
          return {
            ...material,
            link: store.state.baseUrl + material.link,
            file: store.state.baseUrl + material.file,
          };
        }),
    };
    materialList.push(list);
  });
  return materialList;
};
