
import Vue from "vue";
import Title from "@/components/CommonComponents/Title/Title.vue";
import VideoLink from "@/components/CommonComponents/VideoLink/VideoLink.vue";
import MenuWithTabs from "@/components/CommonComponents/MenuWithTabs/MenuWithTabs.vue";
import { Material, MaterialList } from "@/store/types/stateTypes";

export default Vue.extend({
  name: "MaterialsComponent",
  components: { Title, MenuWithTabs, VideoLink },
  data() {
    return {
      currentComponent: "",
      currentDocuments: [] as Material[],
      showAll: false,
    };
  },
  props: {
    materialList: {
      type: Array as () => MaterialList[],
    },
    title: {
      type: String,
    },
  },
  computed: {
    docIcon(): string {
      switch (this.currentComponent) {
        case "Видео":
          return "icon-video";
        case "Ссылки":
          return "icon-link";
        default:
          return "icon-material";
      }
    },
  },
  methods: {
    changeComponent(componentBtn: MaterialList) {
      this.currentComponent = componentBtn.title;
      this.currentDocuments = componentBtn.materials;
      this.showAll = false;
    },
  },
  mounted() {
    this.currentComponent = this.materialList[0]?.title;
    this.currentDocuments = this.materialList[0]?.materials;
  },
});
