
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "ParticipateOwner",
  data() {
    return {
      infoOpenId: -1,
      owners: [
        {
          title: "Агенту",
          profits: [
            "Практическая польза",
            "Новизна практик",
            "Эффективные инструменты работы",
          ],
          img: require("../../assets/Participate/agent.png"),
        },
        {
          title: "Брокеру",
          profits: [
            "Готовые чек листы и скрипты",
            "Новизна практик",
            "Прокачай лидерские навыки",
          ],
          img: require("../../assets/Participate/broker.png"),
        },
        {
          title: "Директору/владелецу",
          profits: [
            '"Встряхни" свою команду',
            "Эффективные инструменты управления",
            "Увеличение контактов и связей",
          ],
          img: require("../../assets/Participate/director.png"),
        },
        {
          title: "Банкам",
          profits: [
            "Заяви о себе",
            "Расширь горизонты продаж",
            "Получи целевую аудиторию",
          ],
          img: require("../../assets/Participate/banks.png"),
        },
        {
          title: "Строительным компаниям",
          profits: [
            "Увеличь дилерскую сеть",
            "Заяви о себе",
            "Тренды потребностей и предпочтений",
          ],
          img: require("../../assets/Participate/companies.png"),
        },
        {
          title: "Страховым компаниям",
          profits: [
            "Расширь горизонты продаж",
            "Увеличь дилерскую сеть",
            "Заяви о себе",
          ],
          img: require("../../assets/Participate/insure.png"),
        },
      ],
    };
  },
  computed: {
    ...mapState(["modules"]),
  },
  methods: {
    infoOpenIdChange(id: number) {
      if (id === this.infoOpenId) {
        this.infoOpenId = -1;
      } else {
        this.infoOpenId = id;
      }
    },
  },
});
