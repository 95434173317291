import { render, staticRenderFns } from "./TariffsTable.vue?vue&type=template&id=4b4d650b&scoped=true&"
import script from "./TariffsTable.vue?vue&type=script&lang=ts&"
export * from "./TariffsTable.vue?vue&type=script&lang=ts&"
import style0 from "./TariffsTable.scss?vue&type=style&index=0&id=4b4d650b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b4d650b",
  null
  
)

export default component.exports