
import Vue from "vue";
import SocialIcons from "@/components/SocialIcons/SocialIcons.vue";
import { mapState } from "vuex";
import { Modules } from "@/store/state";

export default Vue.extend({
  name: "ShareTo",
  components: { SocialIcons },
  computed: {
    ...mapState(["modules"]),
    moduleSocial(): Modules["moduleSocial"] {
      return this.modules.moduleSocial;
    },
  },
});
