
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { inputValidate } from "@/types/types";

export default Vue.extend({
  name: "PersonalCabinetModal",
  data() {
    return {
      callRequest: false,
      errors: {
        phone: "",
        email: "",
        name: "",
        comment: "",
      },
    };
  },
  computed: {
    ...mapState(["modalOrderCall"]),
    formOrder(): inputValidate {
      return this.$refs.formOrder as inputValidate;
    },
  },
  methods: {
    ...mapMutations(["changeModalOrderCall"]),
    ...mapActions(["orderCall"]),
    async orderCallHandler() {
      const valid = this.formOrder.validate();
      if (valid) {
        try {
          this.callRequest = true;
          await this.orderCall();
          const emptyData = {
            opened: false,
            email: "",
            phone: "",
            name: "",
            comment: "",
          };
          this.changeModalOrderCall(emptyData);
          this.errors = {
            phone: "",
            email: "",
            name: "",
            comment: "",
          };
          this.formOrder.resetValidation();
          this.openAlert("Звонок успешно заказан");
        } catch (error) {
          this.errors = {
            phone: "",
            email: "",
            name: "",
            comment: "",
          };
          this.errors = this.validationErrorsHandler(error);
        } finally {
          this.callRequest = false;
        }
      }
    },
  },
});
