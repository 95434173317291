
import Vue from "vue";
import { dayWithEventType } from "@/types/types";

export default Vue.extend({
  name: "CalendarComponent",
  data() {
    return {
      month: 0,
      year: 0,
      activeDay: {
        day: 0,
        month: 0,
        year: 0,
      } as dayWithEventType,
      dFirstMonth: "1",
      day: ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"],
      months: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
      ],
    };
  },
  props: {
    daysWithEvents: {
      type: Array,
      required: true,
    },
    active: {
      type: Object,
    },
  },
  methods: {
    calendar() {
      let days = [] as {
        index: number;
        isCurrent?: boolean;
        withEvent?: boolean;
        notCurrentMonth?: boolean;
      }[][];
      let week = 0;
      days[week] = [];
      let dlast = new Date(this.year, this.month + 1, 0).getDate();
      for (let i = 1; i <= dlast; i++) {
        if (new Date(this.year, this.month, i).getDay() !== +this.dFirstMonth) {
          let a = { index: i, isCurrent: false, withEvent: false };
          days[week].push(a);
          if (
            i === this.activeDay.day &&
            this.month === this.activeDay.month &&
            this.year === this.activeDay.year
          ) {
            a.isCurrent = true;
          }
          if (
            (this.daysWithEvents as dayWithEventType[]).find(
              (el) =>
                el.day === i && el.month === this.month && el.year === this.year
            )
          ) {
            a.withEvent = true;
          }
        } else {
          week++;

          days[week] = [];
          let a = { index: i, isCurrent: false, withEvent: false };
          days[week].push(a);
          if (
            i === this.activeDay.day &&
            this.month === this.activeDay.month &&
            this.year === this.activeDay.year
          ) {
            a.isCurrent = true;
          }
          if (
            (this.daysWithEvents as dayWithEventType[]).find(
              (dayWithEvent) =>
                dayWithEvent.day === i &&
                dayWithEvent.month === this.month &&
                dayWithEvent.year === this.year
            )
          ) {
            a.withEvent = true;
          }
        }
      }

      const length = days[0].length;
      if (days[0].length > 0) {
        for (let i = days[0].length; i < 7; i++) {
          days[0].unshift({
            index: new Date(this.year, this.month, 0).getDate() - i + length,
            notCurrentMonth: true,
          });
        }
      }

      let counter = 1;
      for (let i = 0; i < 6; i++) {
        if (days[i]?.length < 7) {
          for (let y = days[i].length; y < 7; y++) {
            days[i].push({
              index: counter,
              notCurrentMonth: true,
            });
            counter++;
          }
        } else if (!days[i]) {
          days.push([]);
          for (let y = 0; y < 7; y++) {
            days[i][y] = {
              index: counter,
              notCurrentMonth: true,
            };
            counter++;
          }
        }
      }
      return days;
    },
    decrease() {
      this.month--;
      if (this.month < 0) {
        this.month = 12;
        this.month--;
        this.year--;
      }
    },
    increase() {
      this.month++;
      if (this.month > 11) {
        this.month = -1;
        this.month++;
        this.year++;
      }
    },
    updateCurrent(day: {
      index: number;
      isCurrent?: boolean;
      withEvent?: boolean;
      notCurrentMonth?: boolean;
    }) {
      if (day.withEvent) {
        this.activeDay = {
          day: day.index,
          month: this.month,
          year: this.year,
        };
        this.$emit(
          "updateCurrentDate",
          new Date(this.year, this.month, day.index)
        );
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.activeDay = this.active;
      this.month = this.active.month;
      this.year = this.active.year;
      this.$emit(
        "updateCurrentDate",
        new Date(this.activeDay.year, this.activeDay.month, this.activeDay.day)
      );
    }, 100);
  },
});
