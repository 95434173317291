
import Vue from "vue";
import { Accreditation } from "@/store/types/stateTypes";

export default Vue.extend({
  name: "AccreditedCard",
  props: {
    item: {
      type: Object as () => Accreditation,
    },
  },
});
