<template>
  <a class="title" :href="`#${href}`">
    <div
      class="dash"
      :class="{
        dashWhite: white,
      }"
    ></div>
    <h1
      v-if="isH1"
      class="text24 bold"
      :class="{
        'title-whiteColor': white,
      }"
    >
      {{ text }}
    </h1>
    <h2
      v-else
      class="text24 bold"
      :class="{
        'title-whiteColor': white,
      }"
    >
      {{ text }}
    </h2>
    <div
      class="dash"
      :class="{
        dashWhite: white,
      }"
    ></div>
  </a>
</template>

<script>
export default {
  name: "TitleComponent",
  props: {
    text: {
      type: String,
    },
    white: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: "",
    },
    isH1: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
  padding-bottom: 32px;
  text-align: center;

  &-whiteColor {
    color: #fff;
  }
}
</style>
