
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import { Modules } from "@/store/state";
import VideoLink from "@/components/CommonComponents/VideoLink/VideoLink.vue";
import Title from "@/components/CommonComponents/Title/Title.vue";

export default Vue.extend({
  name: "AboutUs",
  components: { VideoLink, Title },
  data() {
    return {};
  },
  computed: {
    ...mapState(["modules", "event"]),
    moduleAbout(): Modules["moduleAbout"] {
      return this.modules.moduleAbout;
    },
  },
  methods: {
    ...mapMutations(["openModalJoin"]),
  },
});
