
import Vue from "vue";
import NewsModal from "@/components/News/NewsModal/NewsModal.vue";
import VideoLink from "@/components/CommonComponents/VideoLink/VideoLink.vue";
import { mapState } from "vuex";
import { newsCardType } from "@/classes/ModulesTypes";

export default Vue.extend({
  name: "NewsCard",
  components: { NewsModal, VideoLink },
  props: {
    card: {
      type: Object as () => newsCardType,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      modal: false,
      imageModal: false,
    };
  },
  computed: {
    ...mapState(["baseUrl"]),
    getLink(): string {
      const url = `/news/${this.card.id}`;
      return `${window.location.origin + url}`;
    },
  },
  methods: {
    modalOpen(events: Event) {
      events.preventDefault();
      this.modal = true;
    },
  },
});
