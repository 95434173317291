import { render, staticRenderFns } from "./JoinModal.vue?vue&type=template&id=7f35644c&scoped=true&"
import script from "./JoinModal.vue?vue&type=script&lang=ts&"
export * from "./JoinModal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f35644c",
  null
  
)

export default component.exports