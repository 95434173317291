
import Title from "@/components/CommonComponents/Title/Title.vue";
import Hexagon from "./Hexagon/Hexagon.vue";
import Vue from "vue";
import { mapState } from "vuex";
import { Partner } from "@/store/types/stateTypes";

type EnumerableObject = { id?: string; name?: string };

export default Vue.extend({
  name: "CertificationComponent",
  components: { Title, Hexagon },
  data() {
    return {
      activeHexagon: {} as EnumerableObject,
      certificateModal: {
        show: false,
        partner: {} as Partner,
      },
    };
  },
  computed: {
    ...mapState(["window", "modules", "event"]),
    title(): string {
      return this.modules.modulePartnerProgram.title;
    },
    currentPartners(): Partner[] {
      return this.modules.modulePartnerProgram.partners.filter(
        (partner: Partner) => partner.category_id === this.activeHexagon.id
      );
    },

    categoryCount(): number {
      const count = Object.values(
        this.modules.modulePartnerProgram.categories
      ).length;
      let sots;
      if (count <= 5) {
        sots = 5;
      } else if (count <= 7) {
        sots = 7;
      } else {
        sots = 9;
      }
      return sots;
    },
    hexagonLocation(): number[][] {
      switch (this.categoryCount) {
        case 9:
          if (this.window >= 1264) {
            return [
              [0, 0, 0, 0],
              [0, 1, 1, 1, 0],
              [1, 1, 1, 1],
              [0, 1, 0, 1, 0],
            ];
          } else if (this.window >= 960) {
            return [
              [0, 1, 1, 0],
              [1, 1, 1],
              [0, 1, 1, 0],
              [1, 0, 1],
            ];
          } else if (this.window >= 600) {
            return [[1, 1], [0, 1, 0], [1, 1], [0, 1, 0], [1, 1], [1]];
          } else {
            return [
              [1, 0],
              [0, 1],
              [1, 0],
              [0, 1],
              [1, 0],
              [0, 1],
              [1, 0],
              [0, 1],
              [1, 0],
            ];
          }
        case 7:
          if (this.window >= 1264) {
            return [
              [0, 0, 0, 0],
              [0, 1, 1, 1, 0],
              [1, 1, 1, 1],
              [0, 0, 0, 0, 0],
            ];
          } else if (this.window >= 960) {
            return [
              [0, 1, 1, 0],
              [1, 1, 1],
              [0, 1, 1, 0],
              [0, 0, 0],
            ];
          } else if (this.window >= 600) {
            return [[0, 0], [0, 1, 0], [1, 1], [0, 1, 0], [1, 1], [1]];
          } else {
            return [
              [1, 0],
              [0, 1],
              [1, 0],
              [0, 1],
              [1, 0],
              [0, 1],
              [1, 0],
            ];
          }
        case 5:
          if (this.window >= 1264) {
            return [
              [0, 0, 0, 0],
              [0, 1, 1, 1, 0],
              [0, 1, 1, 0],
              [0, 0, 0, 0, 0],
            ];
          } else if (this.window >= 960) {
            return [
              [0, 0, 0, 0],
              [1, 1, 1],
              [0, 1, 1, 0],
              [0, 0, 0],
            ];
          } else if (this.window >= 600) {
            return [[0, 0, 0], [1, 1], [0, 1, 0], [1, 1], [0]];
          } else {
            return [
              [1, 0],
              [0, 1],
              [1, 0],
              [0, 1],
              [1, 0],
            ];
          }
        default:
          return [];
      }
    },

    preparedHexagon(): EnumerableObject[][] {
      let index = -1;
      return this.hexagonLocation.map((el: number[]) => {
        return el.map((i: number) => {
          if (i) {
            index++;
            const test = Object.entries(
              this.modules.modulePartnerProgram.categories
            )[index];
            if (test) {
              return { id: test[0] as string, name: test[1] as string };
            }
          }
          return {};
        });
      });
    },
  },
  methods: {
    changeActiveHexagon(event: EnumerableObject) {
      if (this.activeHexagon.id !== event.id) {
        this.changeHash("partnerProgram", { certificateId: String(event.id) });
      }
      this.activeHexagon = event;
      const myEl = document.getElementById("bonus");
      if (myEl) {
        this.$smoothScroll({
          scrollTo: myEl as Element,
          offset: -100,
          updateHistory: false,
        });
      }
    },
    showModal(partner: Partner) {
      this.certificateModal = {
        show: true,
        partner: partner,
      };
    },
  },
  mounted() {
    if (this.$route.query.certificateId) {
      const active = Object.entries(
        this.modules.modulePartnerProgram.categories
      ).find((el) => el[0] === this.$route.query.certificateId);
      if (active) {
        this.activeHexagon = { id: active[0], name: active[1] as string };
      }
    }
  },
});
