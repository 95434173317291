
import Vue from "vue";
import Title from "@/components/CommonComponents/Title/Title.vue";
import { CertificationType } from "@/store/types/stateTypes";
import { smoothToElems } from "@/types";

type btnSettingsType = {
  module: smoothToElems;
  text: string;
};

export default Vue.extend({
  name: "CertificationComponent",
  components: { Title },
  props: {
    certification: {
      type: Object as () => CertificationType,
    },
    title: {
      type: String,
    },
    btnSettings: {
      type: Object as () => btnSettingsType,
    },
  },
  methods: {
    goToBonus(module: smoothToElems | undefined) {
      if (module) {
        this.smoothTo(module);
      }
    },
  },
});
