
import Vue from "vue";
import { opportunity } from "@/types/types";
import { mapState } from "vuex";
import Title from "@/components/CommonComponents/Title/Title.vue";

export default Vue.extend({
  name: "ParticipateComponent",
  components: { Title },
  data() {
    return {
      opportunities: [
        {
          title: "Нет новизны проф. контактов",
          text: "Lorem ipsum dolor sit amet, consectetu adipiscing elit, sed",
        },
        {
          title: "Потрерял возможность первым узнать новые технологии",
          text: "Duis aute irure dolor in reprehenderit",
        },
        {
          title: "Упустил возможность стать эффективнее ",
          text: "Sed ut perspiciatis, unde omnis iste natus",
        },
        {
          title: "Остался в неизвестности",
          text: "Sed ut perspiciatis, unde omnis iste natus",
        },
        {
          title: "Погряз в рутине",
          text:
            "Ut enim ad minim veniam, quis nostrud\n" +
            "exercitation ullamco laboris nisi ut aliquip",
        },
        {
          title: "Получил региональных партнеров",
          text:
            "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris\n" +
            "nisi ut aliquip ex ea commodo consequat.",
        },
        {
          title: "Интегрировал новые продукты в компанию",
          text:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do\n" +
            "eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        },
        {
          title: "Перенял  успешный опыт коллег",
          text:
            "Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris\n" +
            "nisi ut aliquip ex ea commodo consequat.",
        },
        {
          title: "Заявил о себе",
          text:
            "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum\n" +
            "dolore eu fugiat nulla pariatur.",
        },
        {
          title: "Переключился и получил заряд энергии от коллег",
          text: "Sed ut perspiciatis, unde omnis iste natus error sit.",
        },
      ] as opportunity[],
    };
  },
  computed: {
    ...mapState(["modules"]),
    title(): string {
      return this.modules.moduleParticipationReason.title;
    },
    opportunitiesFirstBlock(): opportunity[] {
      return this.opportunities.slice(0, 5);
    },
    opportunitiesSecondBlock(): opportunity[] {
      return this.opportunities.slice(5);
    },
  },
});
