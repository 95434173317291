
import Vue from "vue";
import { RowsTable } from "../Tariffs.vue";
import * as stateTypes from "@/store/types/stateTypes";
import { inputValidate } from "@/types/types";
import { mapActions, mapState } from "vuex";
import {
  AdditionalOptionalType,
  AdditionalTicket,
} from "@/store/types/stateTypes";

export default Vue.extend({
  name: "TariffsTable",
  components: {},
  props: {
    items: {
      required: true,
      type: Array as () => RowsTable[],
    },
    boughtTicketId: {
      type: Number,
    },
    dopTickets: {
      required: true,
      type: Array as () => AdditionalTicket[],
    },
  },
  data() {
    return {
      hidden: true,
      modalTariffBuy: {
        opened: false,
        payerType: 0,
        name: "",
        phone: "",
        email: "",
        comment: "",
        company: "",
        promocode: "",
        promoprice: "",
        title: "",
      },
      promoLoading: false,
      buyTicketLoading: false,
      errors: {} as any,
      modalGoToRegister: false,
      dataForBuy: {
        tariff: undefined as stateTypes.Row | undefined,
        options: [] as AdditionalOptionalType[],
      },
    };
  },
  computed: {
    ...mapState(["authed"]),
    tableRows(): RowsTable[] {
      if (this.hidden) {
        return this.items?.filter((item) => !item.hidden);
      }
      return this.items;
    },
    tariffs(): stateTypes.Row[] {
      return this.tableRows[1]?.rows ?? [];
    },
    titles(): stateTypes.Row[] {
      return this.tableRows[0]?.rows ?? [];
    },
    btnCollapseText(): string {
      return this.hidden ? "Подробнее" : "Свернуть";
    },
    tariffUsersModal() {
      return [
        {
          value: 0,
          text: "Физ. лицо",
        },
        {
          value: 1,
          text: "Юр. лицо",
        },
      ];
    },
    payerInput(): inputValidate {
      return this.$refs.payerInput as inputValidate;
    },
    promoInput(): inputValidate {
      return this.$refs.promoInput as inputValidate;
    },
    expandClass(): any {
      return {
        expand: this.tariffs.length < 5,
      };
    },
    sum(): number {
      let sum = 0;

      if (this.dataForBuy.tariff) {
        sum = sum + +this.dataForBuy.tariff.value;
      }
      this.dataForBuy.options.forEach((el) => {
        sum = sum + el.price;
      });
      if (this.modalTariffBuy.opened && sum <= 0) {
        this.clearModalTariffBuy();
      }

      return sum;
    },
  },
  methods: {
    ...mapActions(["checkPromo", "buyTicket", "buyTicketOrganization"]),
    collapse() {
      this.hidden = !this.hidden;
    },
    changeCheckBox(
      event: boolean,
      type: "tariff" | "options",
      tariff?: stateTypes.Row | undefined,
      option?: AdditionalOptionalType
    ) {
      if (type === "tariff" && tariff) {
        if (!event) {
          this.dataForBuy.tariff = undefined;
        } else {
          this.dataForBuy.tariff = tariff;
        }
      } else if (option) {
        if (event) {
          this.dataForBuy.options.push(option);
        } else {
          this.dataForBuy.options = this.dataForBuy.options.filter(
            (el) => el.id !== option.id
          );
        }
      }
    },
    async applyPromo() {
      const valid = this.promoInput.validate();
      this.promoInput.resetValidation();
      if (valid) {
        this.errors.promo = "";
        try {
          this.promoLoading = true;
          this.modalTariffBuy.promoprice = "";
          const data = await this.checkPromo({
            promo: this.modalTariffBuy.promocode,
            price: this.sum,
          });
          if (data.data.discount === null) {
            this.errors.promo = "Неверный промокод";
          } else {
            this.modalTariffBuy.promoprice = data.data.newPrice.toString();
          }
        } finally {
          this.promoLoading = false;
        }
      }
    },
    async buyTicketHandler() {
      try {
        this.buyTicketLoading = true;
        const data = await this.buyTicket({
          tariff_id: this.dataForBuy.tariff?.id,
          additional: this.dataForBuy.options.map((option) => option.id),
          promo: this.modalTariffBuy.promocode,
        });
        const link = data?.data?.redirect;
        if (link) {
          window.location.href = link;
        }
      } finally {
        this.buyTicketLoading = false;
      }
    },
    async buyTicketOrganizationHandler() {
      const valid = this.payerInput.validate();
      if (valid) {
        try {
          this.buyTicketLoading = true;
          await this.buyTicketOrganization({
            name: this.modalTariffBuy.name,
            tariff_id: this.dataForBuy.tariff?.id,
            additional: this.dataForBuy.options.map((option) => option.id),
            phone: this.modalTariffBuy.phone,
            email: this.modalTariffBuy.email,
            comment: this.modalTariffBuy.comment,
            company: this.modalTariffBuy.company,
          });
          this.modalTariffBuy.opened = false;
          this.clearModalTariffBuy();
          this.openAlert("Запрос на покупку успешно отправлен");
        } finally {
          this.buyTicketLoading = false;
        }
      }
    },
    modalBuyOpen() {
      if (this.authed) {
        this.modalTariffBuy.title = "Корзина";
        this.modalTariffBuy.opened = true;
      } else {
        this.modalGoToRegister = true;
      }
    },
    clearModalTariffBuy() {
      this.modalTariffBuy = {
        opened: false,
        payerType: 0,
        name: "",
        phone: "",
        email: "",
        comment: "",
        company: "",
        promocode: "",
        promoprice: "",
        title: "",
      };
      this.errors = {};
      this.promoInput.resetValidation();
    },
    promocodeInput(value: string) {
      this.errors.promo = "";
      this.modalTariffBuy.promocode = value;
    },
    goToAuthorization() {
      this.$router.push({ name: "Login" });
    },
    goToRegister() {
      this.smoothTo("register-short");
      this.modalGoToRegister = false;
    },
  },
});
