import { ObjectDirective } from "vue";

export const backgroundDirective: ObjectDirective = {
  bind(el, binding) {
    el.style.background = binding.value;
  },
};

export const backgroundImageDirective: ObjectDirective = {
  bind(el, binding) {
    el.style.backgroundImage = "url(" + binding.value + ")";
  },
};

export const colorDirective: ObjectDirective = {
  bind(el, binding) {
    el.style.color = binding.value;
  },
};
