
import Vue from "vue";
import { mapState } from "vuex";
import { Modules } from "@/store/state";
import { VideoModule } from "@/store/types/stateTypes";

export default Vue.extend({
  name: "GreetingsComponent",
  computed: {
    ...mapState(["modules", "sortedModules"]),
    moduleHeader(): Modules["moduleHeader"] {
      return this.modules.moduleHeader;
    },
    isShowModuleVideo(): boolean {
      const index = this.sortedModules.findIndex(
        (module: string) => module === "header"
      );
      return (
        this.modules.moduleVideo.list.length > 0 &&
        this.sortedModules[index + 1] === "video"
      );
    },
    moduleVideo(): VideoModule {
      return this.modules.moduleVideo;
    },
    logoImage() {
      return this.baseUrl + this.modules.moduleHeader.logo;
    },
    backgroundImage() {
      if (
        this.modules.moduleHeader.active &&
        this.modules.moduleHeader.backgroundImage
      ) {
        return this.baseUrl + this.modules.moduleHeader.backgroundImage;
      }
      return "";
    },
    backgroundColor() {
      if (this.backgroundImage) {
        return "unset";
      }
      return this.modules.moduleHeader.backgroundColor ?? "";
    },
  },
});
