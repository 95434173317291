var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"congress container-wrapper",attrs:{"id":"congress"}},[_c('Title',{attrs:{"text":_vm.modules.moduleProgram.title,"href":"program"}}),(_vm.modules.moduleProgram.categories.length > 1)?_c('div',{staticClass:"congress__buttons"},_vm._l((_vm.modules.moduleProgram.categories),function(category,i){return _c('div',{key:i,staticClass:"congress__buttons-button"},[_c('MainButton',{class:[
          {
            active: _vm.categoryActive === category.id,
          },
        ],attrs:{"colorMain":"","width":"100%","disabled":_vm.categoryActive === category.id},on:{"click":function($event){return _vm.updateCurrentCategory(category.id)}}},[_vm._v(_vm._s(category.name)+" ")])],1)}),0):_vm._e(),(_vm.programLocations.length > 0)?_c('div',{staticClass:"congress__locationButtons"},_vm._l((_vm.programLocations),function(location,i){return _c('MainButton',{key:i,staticClass:"congress__locationButtons-button",class:[
        {
          active: _vm.locationActive === location.id,
        },
      ],attrs:{"grayColor":"","disabled":_vm.locationActive === location.id},on:{"click":function($event){return _vm.updateCurrentLocation(location.id)}}},[_c('div',{staticClass:"congress__locationButtons-button-text"},[_vm._v(" "+_vm._s(location.name)+" ")])])}),1):_vm._e(),_c('div',{key:_vm.componentKey,staticClass:"congress__table"},_vm._l((_vm.programList),function(el,index){return _c('div',{key:index},[(el.list?.length > 0 && !el.isBreak)?_c('div',[_c('div',{staticClass:"congress__table__separator"},[_c('div',[_vm._v(" "+_vm._s(el?.time)+" ")]),_c('div',{staticClass:"congress__table__separator-arrow pointer",on:{"click":function($event){return _vm.changeShowProgram(el)}}},[(el.showProgram)?_c('v-img',{attrs:{"width":"17","height":"13","src":require("../../assets/CongressProgram/arrowUp.svg")}}):_c('v-img',{attrs:{"width":"17","height":"13","src":require("../../assets/CongressProgram/arrowDown.svg")}})],1)]),(el.showProgram)?_c('div',_vm._l((el.list),function(program,i){return _c('div',{key:i},[_c('ProgramCard',{attrs:{"programItem":program,"categoryActive":_vm.categoryActive},on:{"openModalPhoto":function($event){return _vm.openModalPhoto($event)}}})],1)}),0):_vm._e()]):(el.isBreak)?_c('div',[_c('ProgramCard',{attrs:{"isBreak":"","program-break":_vm.programBreak}})],1):_vm._e()])}),0),(_vm.modules.moduleProgram.download.active)?_c('div',{staticClass:"d-flex"},[_c('a',{staticClass:"congress__download",attrs:{"href":_vm.modules.moduleProgram.download.link,"target":"_blank"}},[_vm._v(_vm._s(_vm.modules.moduleProgram.download.title))])]):_vm._e(),_c('DefaultModal',{attrs:{"modal":_vm.modalPhoto.opened,"height":"auto","width":"auto"},on:{"close":_vm.clearModalPhoto},scopedSlots:_vm._u([{key:"button",fn:function(){return [_c('v-img',{staticClass:"congress__table__image",attrs:{"src":_vm.modalPhoto.img,"contain":""}})]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }