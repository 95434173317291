
import Vue from "vue";
import { mapState } from "vuex";
import { VideoModule } from "@/store/types/stateTypes";
import Title from "@/components/CommonComponents/Title/Title.vue";
import VideoLink from "@/components/CommonComponents/VideoLink/VideoLink.vue";

export default Vue.extend({
  name: "ParticipateImage",
  components: { Title, VideoLink },
  data() {
    return {
      modal: false,
      modalLoader: false,
    };
  },
  computed: {
    ...mapState(["modules", "sortedModules"]),
    moduleVideo(): VideoModule {
      return this.modules.moduleVideo;
    },
    afterGreeting(): boolean {
      const index = this.sortedModules.findIndex(
        (module: string) => module === "header"
      );
      return this.sortedModules[index + 1] === "video";
    },
  },
  methods: {},
});
