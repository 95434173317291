
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { smoothToElems } from "@/types";
import { NavMenuType } from "@/store/types/stateTypes";

export default Vue.extend({
  name: "App",
  data: () => ({
    mobileMenuOpened: false,
    logoutRequestLoading: false,
  }),
  computed: {
    ...mapState([
      "authed",
      "fullAuthed",
      "user",
      "baseUrl",
      "modules",
      "event",
      "sortedModules",
    ]),
    navMenu(): NavMenuType {
      return this.modules.navMenu;
    },
    showRegistrationBtn(): boolean {
      return this.sortedModules.includes("registration");
    },
    adminPanelLink(): string {
      if (process.env.VUE_APP_IS_DEV === "true") {
        return this.baseUrl + "/back";
      } else {
        return "https://newcongress.online/back";
      }
    },
  },
  methods: {
    ...mapMutations(["openModalPersonalCabinet", "logout"]),
    ...mapActions(["logoutQuery"]),
    openMobileMenu() {
      this.mobileMenuOpened = true;
    },
    closeMobileMenu() {
      this.mobileMenuOpened = false;
    },
    goTo(id: smoothToElems) {
      this.mobileMenuOpened = false;
      this.smoothTo(id);
    },
    FullLogout() {
      if (!this.logoutRequestLoading) {
        this.logoutRequestLoading = true;
        this.logoutQuery().finally(() => {
          this.logoutRequestLoading = false;
        });
      }
    },
  },
});
