
import Vue from "vue";
import { mapState } from "vuex";
import Greetings from "@/components/Greetings/Greetings.vue";
import ParticipateImage from "@/components/ParticipateImage/ParticipateImage.vue";
import Participate from "@/components/Participate/Participate.vue";
import Register from "@/components/Register/Register.vue";
import ListParticipant from "@/components/ListParticipant/ListParticipant.vue";
import Coupons from "@/components/Coupons/Coupons.vue";
import CongressProgram from "@/components/CongressProgram/CongressProgram.vue";
import FullRegistration from "@/components/FullRegistration/FullRegistration.vue";
import ShareTo from "@/components/ShareTo/ShareTo.vue";
import ParticipateOwner from "@/components/ParticipateOwner/ParticipateOwner.vue";
import Sponsor from "@/components/Sponsor/Sponsor.vue";
import Tariffs from "@/components/Tariffs/Tariffs.vue";
import NewsComponent from "@/components/News/News.vue";
import Motivator from "@/components/Motivator/Motivator.vue";
import Propose from "@/components/Propose/Propose.vue";
import EventCalendar from "@/components/EventCalendar/EventCalendar.vue";
import HistoryOfCreation from "@/components/HistoryOfCreation/HistoryOfCreation.vue";
import AboutUs from "@/components/AboutUs/AboutUs.vue";
import Team from "@/components/Team/Team.vue";
import Documents from "@/components/Documents/Documents.vue";
import ContactsModule from "@/components/ContactsModule/ContactsModule.vue";
import Rewards from "@/components/Rewards/Rewards.vue";
import PartnerProgram from "@/components/PartnerProgram/PartnerProgram.vue";
import Certificates from "@/components/Certificates/Certificates.vue";
import ListParticipantCustom from "@/components/ListParticipantCustom/ListParticipantCustom.vue";
import Advertising from "@/components/Advertising/Advertising.vue";
import { smoothToElems } from "@/types";
import Report from "@/components/Report/Report.vue";

type ComponentType = {
  name: string;
  component: string;
  isActive: boolean;
};

export default Vue.extend({
  name: "HomeView",
  components: {
    EventCalendar,
    Greetings,
    ParticipateImage,
    Participate,
    ParticipateOwner,
    Register,
    ListParticipant,
    CongressProgram,
    Coupons,
    FullRegistration,
    ShareTo,
    Sponsor,
    Tariffs,
    Report,
    NewsComponent,
    Motivator,
    Propose,
    HistoryOfCreation,
    AboutUs,
    Team,
    Documents,
    ContactsModule,
    Rewards,
    PartnerProgram,
    Certificates,
    ListParticipantCustom,
    Advertising,
  },
  data() {
    return {
      components: [] as ComponentType[],
      sortedComponents: [] as ComponentType[],
      visibleComponents: [] as string[],
      links: [
        "https://www.youtube.com/watch?v=Xz2kLxrZqy0",
        "https://www.youtube.com/watch?v=uc8Rv4TBUc8",
        "https://www.youtube.com/watch?v=pa4b57fOX_c",
      ],
    };
  },
  computed: {
    ...mapState(["authed", "fullAuthed", "modules", "sortedModules"]),
  },
  methods: {
    onIntersect(entries: IntersectionObserverEntry[]) {
      if (entries[0].isIntersecting) {
        this.visibleComponents.push(entries[0].target.id);
      } else {
        const index = this.visibleComponents.findIndex(
          (module: string) => module === entries[0].target.id
        );
        if (index >= 0) {
          this.visibleComponents.splice(index, 1);
        }
      }
      const activeHash = this.visibleComponents[0];
      if (activeHash) {
        setTimeout(() => {
          if (this.$route.hash.replace("#", "") !== activeHash) {
            this.changeHash(activeHash);
          }
        }, 100);
      }
    },
  },
  mounted() {
    this.components = [
      {
        name: "coupons",
        component: "Coupons",
        isActive:
          this.modules.moduleCoupons.active &&
          this.fullAuthed &&
          this.modules.moduleCoupons.coupons.length > 0,
      },
      {
        name: "header",
        component: "Greetings",
        isActive: this.modules.moduleHeader.active,
      },
      {
        name: "motivator",
        component: "Motivator",
        isActive: this.modules.moduleMotivator.active,
      },
      {
        name: "participantList",
        component: "ListParticipant",
        isActive: this.modules.moduleParticipantList.active,
      },
      {
        name: "participantListCustom",
        component: "ListParticipantCustom",
        isActive: this.modules.moduleParticipantListCustom.active,
      },
      {
        name: "participants",
        component: "ParticipateOwner",
        isActive:
          this.modules.moduleParticipants.active &&
          this.modules.moduleParticipants.participants.length > 0,
      },
      {
        name: "participationReason",
        component: "Participate",
        isActive: this.modules.moduleParticipationReason.active,
      },
      {
        name: "program",
        component: "CongressProgram",
        isActive:
          this.modules.moduleProgram.active &&
          this.modules.moduleProgram.programs.length > 0,
      },
      {
        name: "propose",
        component: "Propose",
        isActive: this.modules.modulePropose.active,
      },
      {
        name: "socials",
        component: "ShareTo",
        isActive: this.modules.moduleSocial.socials.socials.length > 0,
      },
      {
        name: "advertisement",
        component: "Advertising",
        isActive: this.modules.moduleAdvertisement.active,
      },
      {
        name: "sponsor",
        component: "Sponsor",
        isActive:
          this.modules.moduleSponsor.active &&
          this.modules.moduleSponsor.sponsors.length > 0,
      },
      {
        name: "ticket",
        component: "Tariffs",
        isActive:
          this.modules.moduleTicket.active &&
          this.modules.moduleTicket.tariffs.length > 0,
      },
      {
        name: "twoLevelRegistration",
        component: "FullRegistration",
        isActive:
          !this.fullAuthed && this.modules.moduleFullRegistration.active,
      },
      {
        name: "video",
        component: "ParticipateImage",
        isActive: this.modules.moduleVideo.list.length > 0,
      },
      {
        name: "registration",
        component: "Register",
        isActive: !this.authed,
      },
      {
        name: "about",
        component: "AboutUs",
        isActive: true,
      },
      {
        name: "news",
        component: "NewsComponent",
        isActive: this.modules.moduleNews.news.length > 0,
      },
      {
        name: "calendar",
        component: "EventCalendar",
        isActive: this.modules.moduleCalendar.events.length > 0,
      },
      {
        name: "history",
        component: "HistoryOfCreation",
        isActive: this.modules.moduleHistory.history.length > 0,
      },
      {
        name: "teamMember",
        component: "Team",
        isActive: true,
      },
      {
        name: "document",
        component: "Documents",
        isActive: true,
      },
      {
        name: "contact",
        component: "ContactsModule",
        isActive:
          this.modules.moduleContact.contacts.length ||
          this.modules.moduleLocation.map,
      },
      {
        name: "rewards",
        component: "Rewards",
        isActive: this.modules.moduleRewards.rewardList.length > 0,
      },
      {
        name: "partnerProgram",
        component: "PartnerProgram",
        isActive: this.modules.modulePartnerProgram.partners.length > 0,
      },
      {
        name: "certification",
        component: "Certificates",
        isActive: true,
      },
      {
        name: "retrospective",
        component: "Report",
        isActive: true,
      },
    ];
    this.sortedComponents = this.components
      .filter((el) => this.sortedModules.includes(el.name))
      .sort(
        (a, b) =>
          this.sortedModules.indexOf(a.name) -
          this.sortedModules.indexOf(b.name)
      );
    setTimeout(() => {
      const hash = this.$route.hash?.replace("#", "") as smoothToElems;
      if (hash) {
        this.smoothTo(hash);
      }
    }, 100);
  },
});
