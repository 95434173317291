
import Vue from "vue";
import PersonalCabinetModal from "@/components/CommonModals/PersonalCabinet/PersonalCabinet.vue";
import OrderCallModal from "@/components/CommonModals/OrderCall/OrderCall.vue";
import AlertModal from "@/components/CommonModals/AlertModal/AlertModal.vue";
import JoinModal from "@/components/CommonModals/JoinModal/JoinModal.vue";

export default Vue.extend({
  name: "ModalsComponent",
  components: {
    JoinModal,
    PersonalCabinetModal,
    OrderCallModal,
    AlertModal,
  },
});
