var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"menuWithTabs__btns"},_vm._l((_vm.componentBtns),function(componentBtn,i){return _c('div',{key:i,staticClass:"menuWithTabs__btns-btn pointer PC",class:{
        active: _vm.activeComponent === componentBtn.title,
      },on:{"click":function($event){return _vm.changeCurrentComponent(componentBtn)}}},[_c('div',{staticClass:"menuWithTabs__btns-btn__text",class:{
          active: _vm.activeComponent === componentBtn.title,
        }},[_vm._v(" "+_vm._s(componentBtn.title)+" ")]),_c('div',{staticClass:"menuWithTabs__btns-btn__hiding",class:{
          'menuWithTabs__btns-btn__hiding__active':
            _vm.activeComponent === componentBtn.title,
        }})])}),0),_c('div',{staticClass:"menuWithTabs__btns mobile"},[_c('div',{staticClass:"menuWithTabs__btns-btn active pointer"},[_vm._v(" "+_vm._s(_vm.activeComponent)+" "),_c('div',{staticClass:"menuWithTabs__btns-btn__hiding"})]),_c('div',{staticClass:"menuWithTabs__btns-btn pointer"},[(_vm.mobileMenuOpened)?_c('v-icon',{staticClass:"menuWithTabs__menuIcon pointer",attrs:{"color":"white"},on:{"click":function($event){_vm.mobileMenuOpened = false}}},[_vm._v("mdi-window-close ")]):_c('v-icon',{staticClass:"menuWithTabs__menuIcon pointer",attrs:{"color":"white"},on:{"click":function($event){_vm.mobileMenuOpened = true}}},[_vm._v("mdi-menu ")])],1),_c('v-scroll-y-transition',[(_vm.mobileMenuOpened)?_c('div',{staticClass:"menuWithTabs__btns__menu"},_vm._l((_vm.componentBtns),function(componentBtn,i){return _c('div',{key:i,staticClass:"menuWithTabs__btns__menu-item",class:{
            active: _vm.activeComponent === componentBtn.title,
          },on:{"click":function($event){return _vm.changeCurrentComponent(componentBtn)}}},[_vm._v(" "+_vm._s(componentBtn.title)+" ")])}),0):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }