
import Vue from "vue";
import Title from "@/components/CommonComponents/Title/Title.vue";
import { mapState } from "vuex";
import { MassMedia } from "@/store/types/stateTypes";

export default Vue.extend({
  name: "MediaAboutCongress",
  components: { Title },
  data() {
    return {};
  },
  computed: {
    ...mapState(["modules"]),
    title(): string {
      return this.modules.moduleRetrospective.titles.massMediaTitle;
    },
    mediasList(): MassMedia[] {
      return this.modules.moduleRetrospective.massMedia;
    },
    galleryLink(): string {
      return this.modules.moduleRetrospective.gallery;
    },
  },
  methods: {},
});
