
import Vue from "vue";
import Calendar from "./Calendar/Calendar.vue";
import Title from "@/components/CommonComponents/Title/Title.vue";
import VideoLink from "@/components/CommonComponents/VideoLink/VideoLink.vue";
import RegistrationForEvent from "./RegistrationForEvent/RegistrationForEvent.vue";
import { mapState } from "vuex";
import * as stateTypes from "@/store/types/stateTypes";
import { dayWithEventType } from "@/types/types";

export default Vue.extend({
  name: "EventCalendar",
  components: { Calendar, RegistrationForEvent, Title, VideoLink },
  data() {
    return {
      currentDate: null as Date | null,
      showRegistration: false,
      currentEvent: {
        startTime: "",
        endTime: "",
        date: "",
        id: "",
        image: "",
        video: "",
        text: "",
        title: "",
        eventStartTime: "",
        enableRegistration: false,
      } as stateTypes.EventCalendar,
      daysWithEvents: [] as dayWithEventType[],
      activeDay: {} as dayWithEventType,
    };
  },
  computed: {
    ...mapState(["modules"]),
    title(): string {
      return this.modules.moduleCalendar.title;
    },
    btnText(): string {
      return this.modules.moduleCalendar.buttons.registrationButton.text;
    },
    date(): string {
      return this.currentDate
        ? new Date(this.currentDate).toLocaleString("ru", {
            month: "long",
            day: "numeric",
            year: "numeric",
          })
        : "";
    },
    weekday(): string {
      return this.currentDate
        ? new Date(this.currentDate).toLocaleString("ru", {
            weekday: "long",
          })
        : "";
    },
    events(): stateTypes.EventCalendar[] {
      return this.modules.moduleCalendar.events;
    },
    daysEvents(): stateTypes.EventCalendar[] {
      return this.events.filter((el) => el.date === this.date);
    },
    currentEventCompleted(): boolean {
      const currentDate = Date.now();
      return +this.currentEvent.eventStartTime * 1000 < currentDate;
    },
  },
  methods: {
    changeCurrentEvent(events: Event, event: stateTypes.EventCalendar) {
      events.preventDefault();
      if (this.currentEvent.id !== event.id) {
        this.changeHash("calendar", { eventId: String(event.id) });
      }
      this.currentEvent = event;
      this.showRegistration = false;

      const myEl = document.getElementById("event");
      this.$smoothScroll({
        scrollTo: myEl as Element,
        updateHistory: false,
      });
    },
    updateCurrentDate(event: Date) {
      this.currentDate = event;
    },
    goToRegistration() {
      this.showRegistration = true;
      const myEl = document.getElementById("eventRegistration");
      this.$smoothScroll({
        scrollTo: myEl as Element,
        offset: -100,
        updateHistory: false,
      });
    },
  },
  mounted() {
    if (this.$route.query.eventId) {
      this.currentEvent =
        this.events.find((el) => el.id === this.$route.query.eventId) ??
        this.events[0];
    } else {
      this.currentEvent =
        this.events.find((el) => +el.eventStartTime * 1000 > Date.now()) ??
        this.events[0];
    }

    const activeDate = new Date(+this.currentEvent.eventStartTime * 1000);
    this.activeDay = {
      day: activeDate.getDate(),
      month: activeDate.getMonth(),
      year: activeDate.getFullYear(),
    };
    this.events.forEach((event) => {
      const date = new Date(+event.eventStartTime * 1000);
      const dayWithEvent = {
        day: date.getDate(),
        month: date.getMonth(),
        year: date.getFullYear(),
      };
      this.daysWithEvents.push(dayWithEvent);
    });
  },
});
