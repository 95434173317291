
import Vue from "vue";
import VideoLink from "@/components/CommonComponents/VideoLink/VideoLink.vue";
import * as stateTypes from "@/store/types/stateTypes";

export default Vue.extend({
  name: "NewsModal",
  components: { VideoLink },
  data() {
    return {
      imageModal: false,
    };
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
    news: {
      type: Object as () => stateTypes.NewsCard,
    },
  },
});
