
import Vue from "vue";
import Title from "@/components/CommonComponents/Title/Title.vue";
import AccreditedCard from "./AccreditedCard/AccreditedCard.vue";
import { Accreditation } from "@/store/types/stateTypes";
import { mapActions, mapState } from "vuex";

export default Vue.extend({
  name: "AccreditedList",
  components: { Title, AccreditedCard },
  data() {
    return {
      showAll: false,
      textInfo: "Для отображения аккредитованных лиц, необходимо начать поиск",
      name: "",
      agency: "",
      searchLoading: false,
    };
  },
  props: {
    accreditedList: {
      type: Array as () => Accreditation[],
    },
    title: {
      type: String,
    },
  },
  computed: {
    ...mapState(["window"]),
  },
  methods: {
    ...mapActions(["checkAccreditation"]),
    searchAccreditations() {
      this.searchLoading = true;
      this.checkAccreditation({ name: this.name, agency: this.agency })
        .then(() => {
          if (this.accreditedList.length <= 0) {
            this.textInfo = "По вашему запросу ничего не найдено";
          }
        })

        .finally(() => {
          this.searchLoading = false;
        });
    },
  },
});
