
import Vue from "vue";
import MenuWithTabs from "./MenuWithTabs/MenuWithTabs.vue";
import Title from "@/components/CommonComponents/Title/Title.vue";
import { mapMutations, mapState } from "vuex";
import { Document, DocumentList } from "@/store/types/stateTypes";

export default Vue.extend({
  name: "DocumentsComponent",
  components: { MenuWithTabs, Title },
  data() {
    return {
      currentComponent: "",
      currentDocuments: [] as Document[],
      showAll: false,
    };
  },
  computed: {
    ...mapState(["modules", "event"]),
    title(): string {
      return this.modules.moduleDocument.title;
    },
    documentList(): DocumentList[] {
      return this.modules.moduleDocument.documentList;
    },
  },
  methods: {
    ...mapMutations(["openModalJoin"]),
    docIcon(path: string) {
      let type = path.match(/\.([^.]+)$|$/)?.[1];
      switch (type) {
        case "jpg":
          return "jpg.svg";
        case "doc":
        case "docx":
          return "doc.svg";
        case "pdf":
          return "pdf.svg";
        case "xls":
        case "xlsx":
          return "xls.svg";
        default:
          return "document.svg";
      }
    },
    changeComponent(componentBtn: DocumentList) {
      this.currentComponent = componentBtn.title;
      this.currentDocuments = componentBtn.documents;
      this.showAll = false;
    },
  },
  mounted() {
    this.currentComponent = this.documentList[0]?.title;
    this.currentDocuments = this.documentList[0]?.documents;
  },
});
