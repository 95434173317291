
import Vue from "vue";

export default Vue.extend({
  name: "ProposeInfo",
  data() {
    return {
      modalListParticipant: false,
    };
  },
});
