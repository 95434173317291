
import Vue from "vue";
import { mapState } from "vuex";
import { Modules } from "@/store/state";

export default Vue.extend({
  name: "MotivatorComponent",
  computed: {
    ...mapState(["modules"]),
    moduleMotivator(): Modules["moduleMotivator"] {
      return this.modules.moduleMotivator;
    },
    titleBackground(): string {
      const color = this.modules.moduleMotivator.backgroundColorFirst;
      return color ? color : "white";
    },
    subtitleBackground(): string {
      const color = this.modules.moduleMotivator.backgroundColorSecond;
      return color ? color : "white";
    },
    triangleFirstColor(): string {
      return `60px solid ${this.titleBackground}`;
    },
    triangleSecondColor(): string {
      return `80px solid ${this.subtitleBackground}`;
    },
    backgroundImageParticipant(): string {
      return this.baseUrl + this.modules.moduleMotivator.backgroundImage;
    },
  },
});
