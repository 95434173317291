
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import { Social } from "@/store/types/stateTypes";
import { inputValidate } from "@/types/types";

type SocialsType = {
  icon: string;
  link: string;
  name: string;
}[];

export default Vue.extend({
  name: "FooterComponent",
  data() {
    return {
      email: "",
      inputErrors: {
        email: "",
      },
      loading: false,
    };
  },
  computed: {
    ...mapState(["modules"]),
    background(): string {
      return this.modules.moduleFooter.backgroundColor.length
        ? this.modules.moduleFooter.backgroundColor
        : "#2C2C2C";
    },
    linkOffer(): string {
      if (this.modules.moduleFooter.offer === "") {
        return "#";
      }
      return this.baseUrl + this.modules.moduleFooter.offer;
    },
    linkRequisites(): string {
      if (this.modules.moduleFooter.requisites === "") {
        return "#";
      }
      return this.baseUrl + this.modules.moduleFooter.requisites;
    },
    linkPolit(): string {
      if (this.modules.moduleFooter.personalData === "") {
        return "#";
      }
      return this.baseUrl + this.modules.moduleFooter.personalData;
    },
    socials(): SocialsType {
      let socials = [] as SocialsType;
      this.modules.moduleSocial.socials.socials.forEach((social: Social) => {
        let icon = "link.png";
        switch (social.name) {
          case "VK":
            icon = "vk.svg";
            break;
          case "Dzen":
            icon = "dzen.png";
            break;
          case "Telegram":
            icon = "telegram.svg";
            break;
          case "WhatsApp":
            icon = "whatsapp.svg";
            break;
          case "Youtube":
            icon = "youtube.svg";
            break;
          case "Viber":
            icon = "viber.png";
            break;
          case "Skype":
            icon = "skype.png";
            break;
        }
        socials.push({
          icon: icon,
          ...social,
        });
      });
      return socials;
    },
    emailForm(): inputValidate {
      return this.$refs.email as inputValidate;
    },
  },
  methods: {
    ...mapActions(["subscription"]),
    sendEmail() {
      const valid = this.emailForm.validate();
      if (valid) {
        this.loading = true;
        this.subscription({ email: this.email })
          .then(() => {
            this.openAlert("Вы подписались, спасибо!");
            this.email = "";
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
});
