
import Vue from "vue";
import Title from "@/components/CommonComponents/Title/Title.vue";
import { mapMutations, mapState } from "vuex";
import { Contact } from "@/store/types/stateTypes";
import * as stateTypes from "@/store/types/stateTypes";

type socialsListType = {
  name: string;
  link: string;
};

export default Vue.extend({
  name: "ContactsComponent",
  components: { Title },
  data() {
    return {
      socials: [
        "vk",
        "telegram",
        "whatsapp",
        "ok",
        "youtube",
        "viber",
        "skype",
      ] as string[],
    };
  },
  computed: {
    ...mapState(["modules"]),
    title(): string {
      return this.modules.moduleContact.title;
    },
    mainContact(): stateTypes.Contact {
      return this.modules.moduleContact.contacts.find(
        (contact: Contact) => contact.type === "1"
      );
    },
    secondContactsList(): stateTypes.Contact[] {
      return this.modules.moduleContact.contacts.filter(
        (contact: Contact) => contact.type !== "1"
      );
    },
    socialsList(): socialsListType[] {
      let list = [] as socialsListType[];
      this.socials.forEach((social: string) => {
        let link = this.modules.moduleSocial.socials.socials.find(
          (el: socialsListType) => el.name.toLowerCase() === social
        )?.link;
        if (link) {
          list.push({
            name: social,
            link: this.modules.moduleSocial.socials.socials.find(
              (el: socialsListType) => el.name.toLowerCase() === social
            )?.link,
          });
        }
      });
      return list;
    },
  },
  methods: {
    ...mapMutations(["changeModalOrderCall"]),
  },
});
