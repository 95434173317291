
import Vue from "vue";

export default Vue.extend({
  name: "MainBtn",
  props: {
    height: {
      type: String,
      default: "48",
    },
    width: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    colorRed: {
      type: Boolean,
      default: false,
    },
    colorWhite: {
      type: Boolean,
      default: false,
    },
    colorMain: {
      type: Boolean,
      default: false,
    },
    grayColor: {
      type: Boolean,
      default: false,
    },
    withBorder: {
      type: Boolean,
      default: false,
    },
    slim: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    colorButton(): string {
      if (this.colorRed) {
        return "main-btn red";
      }
      if (this.colorWhite) {
        return "main-btn white";
      }
      if (this.colorMain) {
        return "main-btn mainColor";
      }
      if (this.grayColor) {
        return "main-btn grayColor";
      }
      if (this.withBorder) {
        return "main-btn withBorder";
      }
      return "main-btn";
    },
  },
});
