
import Vue from "vue";
import TariffsTable from "@/components/Tariffs/TariffsTable/TariffsTable.vue";
import { mapState } from "vuex";
import * as stateTypes from "@/store/types/stateTypes";

export type RowsTable = {
  id: number;
  title: string;
  text: string;
  type: number;
  hidden: boolean;
  rows: stateTypes.Row[];
};

export default Vue.extend({
  name: "TariffsModule",
  components: {
    TariffsTable,
  },
  computed: {
    ...mapState(["modules"]),
    rows(): stateTypes.Row[] {
      return this.modules.moduleTicket.rows;
    },
    boughtTicketId(): string {
      return this.modules.moduleTicket.boughtTicketId;
    },
    tariffs(): stateTypes.Tariff[] {
      return this.modules.moduleTicket.tariffs;
    },
    additionalTariffs(): stateTypes.AdditionalTicket[] {
      return this.modules.moduleTicket.additional;
    },
    rowsTable(): any {
      const names = this.tariffs.map((tariff) => ({ value: tariff.title }));
      const prices = this.tariffs.map((tariff) => ({
        value: tariff.price,
        id: tariff.id,
      }));
      const rowsTable = this.rows.map((row) => {
        const rows = this.tariffs.map((tariff) => {
          return Object.values(tariff.params).find(
            (param) => +param.rowId == row.id
          );
        });
        return {
          ...row,
          rows,
        };
      });
      return [
        { rows: names, hidden: false, type: 0 },
        { rows: prices, hidden: false, type: 0, title: "Стоимость" },
        ...rowsTable,
      ];
    },
  },
});
