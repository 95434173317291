
import Vue from "vue";
export default Vue.extend({
  name: "TeamMember",
  props: {
    photo: {
      type: String,
    },
    category: {
      type: String,
    },
    name: {
      type: String,
    },
    job_title: {
      type: String,
    },
  },
});
