
import Vue from "vue";
import Title from "@/components/CommonComponents/Title/Title.vue";
import VideoLink from "@/components/CommonComponents/VideoLink/VideoLink.vue";
import { mapState } from "vuex";
import { ListNumbersType, ListVideosType } from "@/store/types/stateTypes";

export default Vue.extend({
  name: "AsItWas",
  components: { Title, VideoLink },
  data() {
    return {};
  },
  computed: {
    ...mapState(["modules"]),
    title(): string {
      return this.modules.moduleRetrospective.titles.retrospectiveTitle;
    },
    numbers(): ListNumbersType {
      return this.modules.moduleRetrospective.numbers;
    },
    videos(): ListVideosType {
      return this.modules.moduleRetrospective.videos;
    },
  },
});
