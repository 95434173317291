import { State } from "./state";
import { MutationTree } from "vuex";
import store from "@/store/index";
import { Accreditation, ModalJoinType } from "@/store/types/stateTypes";
import { newsCardType } from "@/classes/ModulesTypes";

export default {
  writeWindow(state, width: number) {
    state.window = width;
  },
  openModalPersonalCabinet(state) {
    state.modalPersonalCabinet.opened = true;
  },
  openModalOrderCall(state) {
    state.modalOrderCall.opened = true;
  },
  openModalJoin(state) {
    state.modalJoin.opened = true;
  },
  changeModalPersonalCabinet(state, payload) {
    Object.keys(payload).map((key) => {
      if (key in state.modalPersonalCabinet) {
        (state.modalPersonalCabinet as any)[key] = payload[key];
      }
    });
  },
  changeModalOrderCall(state, payload) {
    Object.keys(payload).map((key) => {
      if (key in state.modalOrderCall) {
        (state.modalOrderCall as any)[key] = payload[key];
      }
    });
  },
  changeModalJoin(state, payload) {
    Object.keys(payload).map((key) => {
      if (key in state.modalJoin) {
        state.modalJoin[key as keyof ModalJoinType] = payload[key];
      }
    });
  },
  setUser(state, user: State["user"]) {
    state.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  },
  setAuthed(state, payload: boolean) {
    state.authed = payload;
  },
  setFullAuthed(state, payload: boolean) {
    state.fullAuthed = payload;
  },
  openAlert(state, text: string) {
    state.alertModal = { show: true, text: text };
  },
  closeAlert(state) {
    state.alertModal = { show: false, text: "" };
  },
  logout(state) {
    state.user = {
      id: 0,
      accessBack: false,
      firstName: "",
      lastName: "",
      patronymic: "",
    };
    state.authed = false;
    state.fullAuthed = false;
    localStorage.removeItem("user");
  },
  writeModulesInfo(state, modules: State["modules"]) {
    state.modules = modules;
  },
  writeSortModules(state, namesModules: string[]) {
    state.sortedModules = namesModules;
  },
  changeBaseUrl(state, url: string) {
    state.baseUrl = url;
  },
  writeIndustries(state, payload) {
    state.industries = Object.keys(payload).map((key) => {
      return {
        value: +key,
        text: payload[key],
      };
    });
  },
  writeGenders(state, payload) {
    state.genders = Object.keys(payload).map((key) => {
      return {
        value: +key,
        text: payload[key],
      };
    });
  },
  writeEmploymentTypes(state, payload) {
    state.employmentTypes = Object.keys(payload).map((key) => {
      return {
        value: +key,
        text: payload[key],
      };
    });
  },
  writeCities(state, payload) {
    state.cities = Object.keys(payload).map((key) => {
      return {
        value: +key,
        text: payload[key],
      };
    });
  },
  writeUserInfo(state, payload) {
    state.usersInfo.count = +payload.count;
    state.usersInfo.users = state.usersInfo.users.concat(payload.users);
    state.usersInfo.allUsersLoaded =
      payload.users.length === 0 || payload.users.length < 20;
    state.usersInfo.actualPage += 1;
  },
  writeCongressData(state, payload) {
    state.event = payload;
  },
  verifyEmail(state, payload: boolean) {
    state.isEmailVerified = payload;
  },
  writeAccreditations(state, payload) {
    state.modules.moduleCertification.accreditations = [
      ...payload.map((el: Accreditation) => {
        return {
          ...el,
          photo: el.photo ? store.state.baseUrl + el.photo : "",
          date: new Date(+el.date * 1000).toLocaleString("ru", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
          }),
        };
      }),
    ];
  },
  writeNewsList(state, payload: newsCardType[]) {
    state.modules.moduleNews.news = payload.map((cardNews) => {
      return {
        ...cardNews,
        image: cardNews.image.length
          ? store.state.baseUrl + cardNews.image
          : "",
        created_at: new Date(+cardNews.created_at * 1000).toLocaleString("ru", {
          month: "long",
          day: "numeric",
          year: "numeric",
        }),
      };
    });
  },
} as MutationTree<State>;
