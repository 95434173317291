import { render, staticRenderFns } from "./PersonalCabinet.vue?vue&type=template&id=4f8371fa&scoped=true&"
import script from "./PersonalCabinet.vue?vue&type=script&lang=ts&"
export * from "./PersonalCabinet.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f8371fa",
  null
  
)

export default component.exports