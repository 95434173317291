
import Vue from "vue";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const VueSlickCarousel = require("vue-slick-carousel");
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default Vue.extend({
  name: "MainCarousel",
  components: { VueSlickCarousel },
  props: {
    slides: {
      type: Array,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    initialSlide: {
      type: Number,
      default: 0,
    },
    slidesToShow: {
      type: Number,
      default: 1,
    },
    slidesToScroll: {
      type: Number,
      default: 1,
    },
    dots: {
      type: Boolean,
      default: false,
    },
    arrows: {
      type: Boolean,
      default: true,
    },
    customArrows: {
      type: Boolean,
      default: false,
    },
    centerMode: {
      type: Boolean,
      default: false,
    },
    swipeToSlide: {
      type: Boolean,
      default: false,
    },
    variableWidth: {
      type: Boolean,
      default: false,
    },

    focusOnSelect: {
      type: Boolean,
      default: false,
    },
    responsive: {
      type: Array,
      default: () => [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    },
  },
  data() {
    return {
      settings: {
        arrows: this.arrows,
        autoplay: this.autoplay,
        autoplaySpeed: 6000,
        dots: this.dots,
        infinite: true,
        initialSlide: this.initialSlide,
        slidesToShow: this.slidesToShow,
        slidesToScroll: this.slidesToScroll,
        speed: 1000,
        draggable: false,
        responsive: this.responsive,
        centerMode: this.centerMode,
        swipeToSlide: this.swipeToSlide,
        variableWidth: this.variableWidth,
        focusOnSelect: this.focusOnSelect,
      },
    };
  },
});
