
import Vue from "vue";
import AsItWas from "./AsItWas/AsItWas.vue";
import Reviews from "./Reviews/Reviews.vue";
import MediaAboutCongress from "./MediaAboutCongress/MediaAboutCongress.vue";

export default Vue.extend({
  name: "ReportComponent",
  components: { AsItWas, MediaAboutCongress, Reviews },
});
