
// eslint-disable-next-line @typescript-eslint/no-var-requires
const VueSlickCarousel = require("vue-slick-carousel");
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Vue from "vue";
import VideoLink from "@/components/CommonComponents/VideoLink/VideoLink.vue";
import { Review } from "@/store/types/stateTypes";

export default Vue.extend({
  name: "SliderSlick",
  components: { VueSlickCarousel, VideoLink },
  props: {
    slides: {
      type: Array as () => Review[],
    },
  },
  data() {
    return {
      settings: {
        swipeToSlide: true,
        variableWidth: true,
        focusOnSelect: true,
        arrows: false,
        autoplay: false,
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        infinite: false,
        initialSlide: 0,
        speed: 1000,
        draggable: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 1264,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              rows: 2,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              rows: 2,
            },
          },
        ],
        rows: 1,
      },
      modalSlider: {
        show: false,
        slide: {
          content: "",
          description: "",
          id: null,
          jobTitle: "",
          name: "",
          type: null,
          image: "",
        } as Review,
      },
    };
  },
  methods: {
    showModalSlider(slide: Review) {
      this.modalSlider = {
        show: true,
        slide: slide,
      };
    },
  },
});
