
import Vue from "vue";
import { mask } from "vue-the-mask";

type rules = "required" | "phone";

type validateRulesField = (v: string) => string | boolean;
type validateRules = {
  [key in rules]: validateRulesField;
};

export default Vue.extend({
  name: "PhoneInput",
  directives: { mask },
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "Телефон",
    },
    rules: {
      type: Array as unknown as () => rules[],
      default: () => [],
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    prefix: {
      type: String,
      default: "+7",
    },
  },
  data() {
    return {
      passwordVisible: false,
      validateRules: {
        required: (v: string) => v !== "" || "Обязательное поле",
        phone: (v: string) => {
          // eslint-disable-next-line
          let pattern = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;
          return pattern.test(v) || v === "" || "Некорректный номер телефона";
        },
      } as validateRules,
    };
  },
  methods: {
    updateValue(val: string): void {
      const phone = val.replace(/[^\d]/g, "");
      this.$emit("input", phone);
    },
  },
  computed: {
    enabledRules() {
      let rules: validateRulesField[] = [];
      Object.keys(this.validateRules).map((rule) => {
        if (this.rules !== undefined && this.rules.includes(rule as rules)) {
          rules.push(this.validateRules[rule as rules]);
        }
      });
      if (!this.rules.find((rule) => rule === "phone")) {
        rules.push(this.validateRules["phone"]);
      }

      return rules;
    },
    inputValue: {
      set(val: string) {
        const phone = val.replace(/[^\d]/g, "");
        this.$emit("input", phone);
        return val;
      },
      get(): string {
        if (this.value === "") return "";

        const length6 = `(${this.value.slice(0, 3)})${this.value.slice(3, 6)}`;
        const length8 = length6 + "-" + this.value.slice(6, 8);
        const fullLength = length8 + "-" + this.value.slice(8);

        if (this.value.length <= 3) {
          return "(" + this.value;
        }
        if (this.value.length <= 6) {
          return length6;
        }
        if (this.value.length <= 8) {
          return length8;
        }
        return fullLength;
      },
    },
  },
});
