
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import { Modules } from "@/store/state";
import { Coupon } from "@/store/types/stateTypes";

export default Vue.extend({
  name: "CouponsComponent",
  data() {
    return {
      couponSending: false,
      modalGoToRegister: false,
      modalCoupon: false,
      modalCouponInfo: {} as Coupon,
    };
  },
  computed: {
    ...mapState(["fullAuthed", "authed", "modules"]),
    coupons(): Modules["moduleCoupons"]["coupons"] {
      return this.modules.moduleCoupons.coupons;
    },
    firstCoupon(): Coupon {
      return this.coupons[0];
    },
    secondCoupon(): Coupon {
      return this.coupons[1];
    },
    thirdCoupon(): Coupon {
      return this.coupons[2];
    },
  },
  methods: {
    ...mapActions(["orderCoupon"]),
    modalCouponInfoOpen(coupon: Coupon) {
      this.modalCouponInfo = coupon;
      this.modalCoupon = true;
    },
    async orderCouponHandler() {
      if (this.couponSending) {
        return;
      }
      this.couponSending = true;
      try {
        await this.orderCoupon({
          id: this.modalCouponInfo.id,
        });
        this.openAlert("Письмо с купоном отправлено вам на почту");
      } catch (error: any) {
        const defaultMessage = "Ошибка получения купона";
        const errorMessage = error.response.data?.message;
        const message = errorMessage ? errorMessage : defaultMessage;
        this.openAlert(message);
      } finally {
        this.couponSending = false;
        this.modalCoupon = false;
      }
    },
  },
});
