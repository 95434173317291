import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueCountdown from "@chenfengyuan/vue-countdown";
import "@/styles/style.scss";
import VueSmoothScroll from "vue2-smooth-scroll";
import apiMixin from "@/mixins/Common/Api";
import {
  backgroundDirective,
  backgroundImageDirective,
  colorDirective,
} from "@/directives/inlineStyles";
import commonMixin from "@/mixins/Common/Common";
import "@/mixins/Common/GlobalComponents";
import VueSocialSharing from "vue-social-sharing";

Vue.use(VueSmoothScroll);
Vue.use(VueSocialSharing);

Vue.config.productionTip = false;
Vue.component(VueCountdown.name, VueCountdown);

Vue.mixin(apiMixin);
Vue.mixin(commonMixin);

Vue.directive("background", backgroundDirective);
Vue.directive("backgroundImage", backgroundImageDirective);
Vue.directive("color", colorDirective);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
