
import Vue from "vue";
import { mapState } from "vuex";
import Title from "@/components/CommonComponents/Title/Title.vue";
import ProgramCard from "./ProgramCard/ProgramCard.vue";
import * as stateTypes from "@/store/types/stateTypes";
import {
  EnumerableObjectWithID,
  Program,
  RangesType,
} from "@/store/types/stateTypes";

type ProgramType = {
  id?: number;
  isBreak: boolean;
  list?: Program[];
  showProgram?: boolean;
  time?: string;
  break?: Program;
};

export default Vue.extend({
  name: "CongressProgram",
  components: { Title, ProgramCard },
  data() {
    return {
      categoryActive: "",
      locationActive: "",
      modalPhoto: {
        opened: false,
        img: "",
      },
      componentKey: 0,
      showProgram: {
        beforeBreak: true,
        afterBreak: true,
      },
      programList: [] as ProgramType[],
    };
  },
  computed: {
    ...mapState(["modules"]),
    programLocations(): EnumerableObjectWithID[] {
      const locationsID: string[] = [];
      this.modules.moduleProgram.programs.map((program: stateTypes.Program) => {
        if (
          !locationsID.includes(program.room_id) &&
          program.category_id === this.categoryActive
        ) {
          locationsID.push(program.room_id);
        }
      });
      this.modules.moduleProgram.rooms.filter((room: EnumerableObjectWithID) =>
        locationsID.includes(room.id)
      );
      return this.modules.moduleProgram.rooms.filter(
        (room: EnumerableObjectWithID) => locationsID.includes(room.id)
      );
    },
    programBreak(): Program {
      return this.modules.moduleProgram.programs.find(
        (el: stateTypes.Program) =>
          !!el.details_id &&
          this.categoryActive === el.category_id &&
          this.locationActive === el.room_id
      );
    },
  },
  methods: {
    updateProgramList() {
      this.programList = [];
      this.modules.moduleProgram.ranges.forEach(
        (el: RangesType, index: number) => {
          let list = this.modules.moduleProgram.programs.filter(
            (program: stateTypes.Program) => {
              if (
                program.category_id === this.categoryActive &&
                program.room_id === this.locationActive &&
                program.time <= el.time_to &&
                program.time >= el.time_from &&
                !program.details_id
              ) {
                return program;
              }
            }
          );

          this.programList.push({
            time: `С ${el.time_from} до ${el.time_to}`,
            list: list,
            id: index,
            showProgram: true,
            isBreak: false,
          });
          if (this.programBreak) {
            if (
              this.programBreak.time >= el.time_to &&
              this.programBreak.time <=
                this.modules.moduleProgram.ranges[index + 1].time_from
            ) {
              this.programList.push({
                break: this.programBreak,
                isBreak: true,
              });
            }
          }
        }
      );
    },
    changeShowProgram(program: ProgramType) {
      this.programList = this.programList.map((el: ProgramType) =>
        el.id === program.id ? { ...el, showProgram: !el.showProgram } : el
      );
    },
    forceRerender() {
      this.updateProgramList();
      this.componentKey += 1;
    },
    writeCurrentLocation() {
      this.locationActive = this.programLocations[0]?.id;
    },

    updateCurrentLocation(locations: string) {
      this.locationActive = locations;
      this.forceRerender();
    },
    updateCurrentCategory(category: string) {
      this.categoryActive = category;
      this.writeCurrentLocation();
      this.forceRerender();
    },
    openModalPhoto(photo: string) {
      this.modalPhoto = {
        opened: true,
        img: photo,
      };
    },
    clearModalPhoto() {
      this.modalPhoto = {
        opened: false,
        img: " ",
      };
    },
  },

  mounted() {
    this.updateCurrentCategory(this.modules.moduleProgram.categories[0]?.id);
  },
});
