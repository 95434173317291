
import Vue from "vue";
import NavHeader from "@/components/NavHeader/NavHeader.vue";
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
import Modals from "@/components/CommonModals/Modals.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { ModulesClass } from "@/classes/Modules";
import { Modules } from "@/store/state";

export default Vue.extend({
  name: "App",
  components: { NavHeader, Modals, Footer, Header },
  data() {
    return {
      loadingApp: false,
      infoError: false,
    };
  },
  computed: {
    ...mapState(["modules", "event"]),
    customCss(): Modules["customCss"] {
      return this.modules.customCss;
    },
  },
  beforeMount() {
    const baseUrl = window.location.origin;
    const hostName = window.location.hostname;
    this.changeBaseUrl(baseUrl);

    if (process.env.VUE_APP_IS_DEV === "true") {
      this.changeBaseUrl("http://localhost:90");
    }

    const subdomain = /^([a-z0-9]{1,}).newcongress.online/gi.exec(hostName);

    if (process.env.VUE_APP_IS_DEV !== "true") {
      if (
        (!subdomain || subdomain[1] === "www") &&
        hostName.includes("newcongress.online")
      ) {
        window.location.href = "https://newcongress.online/back";
      }
    }
  },

  async mounted() {
    try {
      this.loadingApp = true;
      const data = await this.getAppInfo();
      const modules = new ModulesClass(data.data.modules);
      this.writeModulesInfo(modules);
      this.writeSortModules(Object.keys(data.data.modules));
      this.getUsers();
      const user = data.data.user;
      if (user) {
        const userFormatted = {
          id: user.id,
          accessBack: user.access_back,
          firstName: user.first_name,
          lastName: user.last_name,
          patronymic: user.patronymic,
        };
        this.setUser(userFormatted);
        this.setAuthed(true);
        if (user.full_registered) {
          this.setFullAuthed(true);
        }
      }
      this.writeIndustries(data.data.industry);
      this.writeGenders(data.data.gender);
      this.writeEmploymentTypes(data.data.employment_types);
      this.writeCities(data.data.city);
      this.writeCongressData(data.data.event);
      // TODO: зачем это сделано, мы же берем из настроек конгресса
      //document.title = data.data.modules?.header?.title ?? "Конгресс";

      if (data.data.event.id === 1) {
        this.writeYandexMetrika();
      }
      const styleSheet = document.createElement("style");
      styleSheet.innerText = this.customCss.style;
      document.head.appendChild(styleSheet);
    } catch {
      this.infoError = true;
    } finally {
      this.loadingApp = false;
    }
  },
  methods: {
    ...mapMutations([
      "writeWindow",
      "setUser",
      "setAuthed",
      "writeModulesInfo",
      "writeSortModules",
      "changeBaseUrl",
      "setFullAuthed",
      "writeGenders",
      "writeIndustries",
      "writeEmploymentTypes",
      "writeCities",
      "writeCongressData",
    ]),
    ...mapActions(["getAppInfo", "getUsers"]),
    onResize() {
      this.writeWindow(window.innerWidth);
    },
    writeYandexMetrika() {
      let script = document.createElement("script");
      script.type = "text/javascript";
      script.innerHTML =
        "    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n" +
        "        m[i].l=1*new Date();\n" +
        "        for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}\n" +
        "        k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n" +
        '    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");\n' +
        "\n" +
        '    ym(97823563, "init", {\n' +
        "        clickmap:true,\n" +
        "        trackLinks:true,\n" +
        "        accurateTrackBounce:true,\n" +
        "        webvisor:true\n" +
        "    });";
      document.getElementsByTagName("head")[0].appendChild(script);
      let noscript = document.createElement("noscript");
      noscript.innerHTML =
        '<div><img src="https://mc.yandex.ru/watch/97823563" style="position:absolute; left:-9999px;" alt="" /></div>';
      document.getElementsByTagName("head")[0].appendChild(noscript);
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
});
