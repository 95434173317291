var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"history"},[_c('div',{staticClass:"container-wrapper"},[_c('Title',{attrs:{"text":_vm.title,"white":"","href":"history"}}),_c('div',{staticClass:"history__container"},[(_vm.description.length)?_c('div',{staticClass:"history__container-text",domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e(),_c('div',{staticClass:"history__box"},[_c('div',{staticClass:"history__box-years"},_vm._l((_vm.historyList),function(item,i){return _c('div',{key:i,staticClass:"history__box-years-btnBox"},[_c('MainButton',{staticClass:"history__box-years-btnBox-btn",class:{
                'history__box-years-btnBox-btn active':
                  _vm.activeYear === item.year,
              },on:{"click":function($event){_vm.activeYear = item.year}}},[_vm._v(" "+_vm._s(item.year)+" ")]),(_vm.activeYear === item.year)?_c('div',{staticClass:"history__box-years-btnBox-arrow"}):_vm._e()],1)}),0),_c('div',_vm._l((_vm.currentHistoryList),function(item,i){return _c('div',{key:i},[_c('div',{staticClass:"history__box-years-title bold"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"history__box-years-description"},[(item.video)?_c('div',{staticClass:"history__box-years-description-imgBox",class:{
                  left: i % 2 === 0,
                  right: i % 2 !== 0,
                }},[_c('VideoLink',{staticClass:"history__box-years-description-imgBox-img",attrs:{"link":item.video,"id":item.id,"isPoster":!item.image,"poster":item.image}})],1):(item?.image)?_c('div',{staticClass:"history__box-years-description-imgBox",class:{
                  left: i % 2 === 0,
                  right: i % 2 !== 0,
                }},[_c('v-img',{staticClass:"history__box-years-description-imgBox-img",attrs:{"src":item?.image,"cover":"","alt":item.title}})],1):_vm._e(),_vm._v(" "+_vm._s(item.text)+" ")]),_c('div',{staticClass:"history__box-years-line",class:{
                last: i === _vm.currentHistoryList.length - 1,
              }})])}),0)])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }