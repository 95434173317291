
import Vue from "vue";
import { DocumentList } from "@/store/types/stateTypes";

export default Vue.extend({
  name: "MenuWithTabs",
  data() {
    return {
      activeComponent: "" as string,
      mobileMenuOpened: false,
    };
  },
  props: {
    componentBtns: {
      type: Array,
      default: [] as DocumentList[],
    },
    currentComponent: {
      type: String,
    },
  },
  methods: {
    changeCurrentComponent(componentBtb: DocumentList) {
      this.$emit("changeCurrentComponent", componentBtb);
      this.mobileMenuOpened = false;
    },
  },
  watch: {
    currentComponent() {
      this.activeComponent = this.currentComponent;
    },
  },
});
