
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import { State } from "@/store/state";

export default Vue.extend({
  name: "ListParticipant",
  data() {
    return {
      modalListRegistered: false,
      usersLoading: false,
    };
  },
  computed: {
    ...mapState(["usersInfo", "modules", "event"]),
    users(): State["usersInfo"]["users"] {
      return this.usersInfo.users;
    },
    title(): string {
      return this.modules.moduleParticipantList.title;
    },
  },
  methods: {
    ...mapActions(["getUsers"]),
    async usersAppend() {
      this.usersLoading = true;
      try {
        await this.getUsers(false);
      } finally {
        this.usersLoading = false;
      }
    },
  },
});
