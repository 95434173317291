
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { smoothToElems } from "@/types";
import { NavMenuType, Social } from "@/store/types/stateTypes";

type SocialsType = {
  icon: string;
  link: string;
  name: string;
}[];

export default Vue.extend({
  name: "NavHeader",
  data: () => ({
    mobileMenuOpened: false,
    logoutRequestLoading: false,
    showSocialModal: false,
    menuItems: ["Конгресс", "Участие", "Спикеры"],
  }),
  computed: {
    ...mapState([
      "authed",
      "fullAuthed",
      "user",
      "baseUrl",
      "modules",
      "sortedModules",
    ]),
    navMenu(): NavMenuType {
      return this.modules.navMenu;
    },
    showRegistrationBtn(): boolean {
      return this.sortedModules.includes("registration");
    },
    socials(): SocialsType {
      let socials = [] as SocialsType;
      this.modules.moduleSocial.socials.socials.forEach((social: Social) => {
        let icon = "link.png";
        switch (social.name) {
          case "VK":
            icon = "vk.svg";
            break;
          case "Dzen":
            icon = "dzen.png";
            break;
          case "Telegram":
            icon = "telegram.svg";
            break;
          case "WhatsApp":
            icon = "whatsapp.svg";
            break;
          case "Youtube":
            icon = "youtube.svg";
            break;
          case "Viber":
            icon = "viber.png";
            break;
          case "Skype":
            icon = "skype.png";
            break;
        }
        socials.push({
          icon: icon,
          ...social,
        });
      });
      return socials;
    },
    adminPanelLink(): string {
      if (process.env.VUE_APP_IS_DEV === "true") {
        return this.baseUrl + "/back";
      } else {
        return "https://newcongress.online/back";
      }
    },
  },
  methods: {
    ...mapMutations(["openModalPersonalCabinet", "logout"]),
    ...mapActions(["logoutQuery"]),
    openMobileMenu() {
      this.mobileMenuOpened = true;
    },
    closeMobileMenu() {
      this.mobileMenuOpened = false;
    },
    goTo(id: smoothToElems) {
      this.mobileMenuOpened = false;
      this.smoothTo(id);
    },
    FullLogout() {
      if (!this.logoutRequestLoading) {
        this.logoutRequestLoading = true;
        this.logoutQuery().finally(() => {
          this.logoutRequestLoading = false;
        });
      }
    },
  },
});
