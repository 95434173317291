
import Vue from "vue";

export default Vue.extend({
  name: "VideoLink",
  data() {
    return {
      modal: false,
      modalLoader: false,
    };
  },
  props: {
    link: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      required: true,
    },
    isPoster: {
      type: Boolean,
    },
    poster: {
      type: String,
    },
    videoTitle: {
      type: String,
    },
    icon: {
      type: Boolean,
    },
    isSlot: {
      type: Boolean,
    },
  },
  computed: {
    isDzen(): boolean {
      return this.link?.includes("dzen");
    },
    videoLinkCode(): string {
      let regexp = new RegExp("\\?v=([^&]+)(&.*)?$", "i");
      return (
        this.link.match(regexp)?.[1] ??
        this.link.replace("https://youtu.be/", "")
      );
    },
    video(): string {
      if (this.isDzen) {
        return this.link;
      }
      return `https://www.youtube.com/embed/${this.videoLinkCode}`;
    },
    imgSrc(): string {
      if (this.poster) {
        return this.poster;
      }
      return `//img.youtube.com/vi/${this.videoLinkCode}/mqdefault.jpg`;
    },
  },
  methods: {
    openVideo(link: string, id: string) {
      this.modal = true;
      this.modalLoader = true;
      setTimeout(() => {
        const frame = `<iframe v-if="link" class="video__frame" style="position:absolute; left: 0; top: 0; width: 100%; height: 100%; border: 0" src="${link}" title="video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
        document
          .querySelector(`#${CSS.escape(id)}`)
          ?.insertAdjacentHTML("afterbegin", frame);
        this.modalLoader = false;
      }, 1000);
    },
    closeVideo() {
      document.querySelector(".video__frame")?.remove();
      this.modal = false;
    },
  },
});
