import { render, staticRenderFns } from "./Reviews.vue?vue&type=template&id=6b5c24ee&scoped=true&"
import script from "./Reviews.vue?vue&type=script&lang=ts&"
export * from "./Reviews.vue?vue&type=script&lang=ts&"
import style0 from "./Reviews.scss?vue&type=style&index=0&id=6b5c24ee&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b5c24ee",
  null
  
)

export default component.exports