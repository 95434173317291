
import Vue from "vue";
import { inputValidate } from "@/types/types";
import Title from "@/components/CommonComponents/Title/Title.vue";
import { mapActions, mapMutations, mapState } from "vuex";
import {
  ShortRegistrationClass,
  FullRegistrationClass,
} from "@/components/FullRegistration/RegistrationClasses";

export default Vue.extend({
  name: "FullRegistration",
  components: { Title },
  data() {
    return {
      name: "",
      secondName: "",
      patronymic: "",
      email: "",
      phone: "",
      specialization: "",
      experienceInSpecialization: "",
      promocode: "",
      password: "",
      passwordConfirm: "",
      region: "",
      gender: "",
      industry: "",
      employmentType: "",
      post: "",
      company: "",
      site: "",
      amountEmployees: "",
      entrepreneurshipTerm: "",
      billingDetails: "",
      taxationSystem: "",
      dateOfBirth: "",
      incomeLevel: "",
      agreement: false,
      errors: {} as { [key: string]: string },
      registerLoading: false,
    };
  },
  computed: {
    ...mapState([
      "authed",
      "genders",
      "cities",
      "industries",
      "employmentTypes",
      "modules",
    ]),
    title(): string {
      return this.modules.moduleFullRegistration.title;
    },
    formInputs(): inputValidate {
      return this.$refs.formInputs as inputValidate;
    },
  },
  methods: {
    ...mapActions(["fullRegistration"]),
    ...mapMutations(["setFullAuthed"]),
    async revealBonuses() {
      const valid = this.formInputs.validate();
      if (valid) {
        this.errors = {};
        let filledFields = {} as any;
        if (this.authed) {
          /** Короткая регистрация */
          filledFields = new ShortRegistrationClass(this).getFilledFields();
        } else {
          /** Полная регистрация */
          filledFields = new FullRegistrationClass(this).getFilledFields();
        }
        try {
          this.registerLoading = true;
          const data = await this.fullRegistration(filledFields);
          if (this.authed) {
            this.openAlert("Полная регистрация успешно завершена!");
            this.setFullAuthed(true);
          } else {
            this.$router.push({ name: "Login" });
            this.openAlert(
              "Регистрация прошла успешно, на ваш email пришло письмо для подтверждения"
            );
          }
        } catch (error) {
          this.errors = this.validationErrorsHandler(error);
        } finally {
          this.registerLoading = false;
        }
      }
    },
  },
});
