
import Vue from "vue";
import * as stateTypes from "@/store/types/stateTypes";
import { mapState } from "vuex";

export default Vue.extend({
  name: "AdvertisingComponent",
  computed: {
    ...mapState(["baseUrl", "modules"]),
    shareAdvertising(): stateTypes.Advertisement[] {
      return this.modules.moduleAdvertisement.second;
    },
  },
  methods: {
    advertisingLink(id: number): string {
      return this.baseUrl + `/back/redirect/advertisement?id=${id}`;
    },
    imageLink(image: string): string {
      return this.baseUrl + image;
    },
  },
});
