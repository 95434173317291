import Vue from "vue";
import MainSpinner from "@/components/CommonComponents/MainSpinner/MainSpinner.vue";
import MainButton from "@/components/CommonComponents/MainButton/MainButton.vue";
import MainInput from "@/components/CommonComponents/MainInput/MainInput.vue";
import MainSelectInput from "@/components/CommonComponents/MainSelectInput/MainSelectInput.vue";
import DefaultModal from "@/components/DefaultModal/DefaultModal.vue";
import PhoneInput from "@/components/CommonComponents/PhoneInput/PhoneInput.vue";
import DatePicker from "@/components/CommonComponents/DatePicker/DatePicker.vue";
import MainBtn from "@/components/CommonComponents/MainButton/MainButton.vue";
import MainCarousel from "@/components/CommonComponents/MainCarousel/MainCarousel.vue";

Vue.component("MainSpinner", MainSpinner);
Vue.component("MainButton", MainButton);
Vue.component("MainInput", MainInput);
Vue.component("PhoneInput", PhoneInput);
Vue.component("MainSelectInput", MainSelectInput);
Vue.component("DefaultModal", DefaultModal);
Vue.component("DatePicker", DatePicker);
Vue.component("MainBtn", MainBtn);
Vue.component("MainCarousel", MainCarousel);
