
import Vue from "vue";
import { CustomAxiosError, inputValidate } from "@/types/types";
import { mapActions, mapState } from "vuex";
import Title from "@/components/CommonComponents/Title/Title.vue";

export default Vue.extend({
  name: "RegisterComponent",
  components: { Title },
  data() {
    return {
      agreement: false,
      phone: "",
      email: "",
      name: "",
      secondName: "",
      password: "",
      passwordConfirm: "",
      company: "",
      region: "",
      registerRequestSending: false,
      inputErrors: {
        phone: "",
        email: "",
        last_name: "",
        first_name: "",
        password: "",
        confirm_password: "",
        common: "",
        city_id: "",
      },
    };
  },
  computed: {
    ...mapState(["cities", "modules"]),
    title(): string {
      return this.modules.moduleRegistration.title;
    },
    formInputs(): inputValidate {
      return this.$refs.formInputs as inputValidate;
    },
  },
  methods: {
    ...mapActions(["registration"]),
    register() {
      const valid = this.formInputs.validate();
      if (valid) {
        const user = {
          phone: this.phone,
          email: this.email,
          last_name: this.secondName,
          first_name: this.name,
          password: this.password,
          confirm_password: this.passwordConfirm,
          company: this.company,
          city_id: this.region,
        };
        this.registerRequestSending = true;
        this.registration(user)
          .then(() => {
            this.openAlert(
              "Регистрация прошла успешно, на ваш email пришло письмо для подтверждения"
            );
            this.$router.push({ name: "Login" });
          })
          .catch((error: CustomAxiosError) => {
            if (
              error?.response?.status === 400 &&
              error?.response?.data.name === "Errors of validation"
            ) {
              const data = error?.response?.data;
              if (data?.message) {
                this.inputErrors.common = error.response.data.message;
              }
              if (Object.keys(data.fail).length) {
                Object.keys(data.fail).map((el) => {
                  (this.inputErrors as any)[el] = data.fail[el][0];
                });
              }
            }
          })
          .finally(() => {
            this.registerRequestSending = false;
          });
        this.formInputs.resetValidation();
      }
    },
  },
});
