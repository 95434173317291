
import Vue from "vue";
import Title from "@/components/CommonComponents/Title/Title.vue";
import SliderSlick from "@/components/CommonComponents/Slider/SliderSlick.vue";
import { mapState } from "vuex";
import { Review } from "@/store/types/stateTypes";

export default Vue.extend({
  name: "ReviewsComponent",
  components: { Title, SliderSlick },
  data() {
    return {};
  },
  computed: {
    ...mapState(["modules"]),
    title(): string {
      return this.modules.moduleRetrospective.titles.reviewTitle;
    },
    reviews(): Review[] {
      return this.modules.moduleRetrospective.reviews;
    },
  },
});
