var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"aboutUs",style:([
      _vm.moduleAbout.about_background_image.length
        ? {
            backgroundImage: `url(${_vm.moduleAbout.about_background_image})`,
          }
        : '',
    ])},[_c('div',{staticClass:"container-wrapper"},[_c('Title',{attrs:{"is-h1":"","text":_vm.moduleAbout.about_title,"white":"","href":"about"}}),_c('div',{staticClass:"aboutUs__description",attrs:{"id":"aboutUsDescription"},domProps:{"innerHTML":_vm._s(_vm.moduleAbout.about_text)}}),(_vm.event.id === 3)?_c('div',{staticClass:"aboutUs__btn"},[_c('MainButton',{attrs:{"color-red":""},on:{"click":_vm.openModalJoin}},[_vm._v("Вступить")])],1):_vm._e()],1)]),_c('div',{staticClass:"mission",attrs:{"id":"mission"}},[_c('div',{staticClass:"container-wrapper"},[_c('Title',{attrs:{"text":_vm.moduleAbout.mission_title,"href":"mission"}}),_c('div',{staticClass:"mission__description"},[(_vm.moduleAbout.mission_link)?_c('div',{staticClass:"mission__description-img"},[_c('VideoLink',{attrs:{"link":_vm.moduleAbout.mission_link,"id":"missionVideo","isPoster":!_vm.moduleAbout.mission_image,"poster":_vm.moduleAbout.mission_image}})],1):_c('img',{staticClass:"mission__description-img",attrs:{"src":_vm.moduleAbout?.mission_image,"alt":"mission_image"}}),_c('div',{staticClass:"mission__description-text",domProps:{"innerHTML":_vm._s(_vm.moduleAbout.mission_text)}})])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }