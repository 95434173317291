import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/Main/HomeView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Main",
    component: HomeView,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Authorization/Login/Login.vue"),
  },
  {
    path: "/confirm/email",
    name: "ConfirmEmail",
    component: () => import("../components/ConfirmEmail/ConfirmEmail.vue"),
  },
  {
    path: "/password/reset",
    name: "PasswordRestore",
    component: () =>
      import("../views/Authorization/PasswordReset/PasswordReset.vue"),
  },
  {
    path: "/news/:id",
    name: "News",
    component: () => import("../views/NewsId/NewsId.vue"),
  },
  {
    path: "*",
    redirect: () => {
      const baseUrl = window.location.origin + "/";
      window.location.href = `${baseUrl}back/e404`;
      return {};
    },
  },
  {
    path: "/pay-success",
    name: "PaySuccess",
    component: () => import("@/views/Payment/SuccessPay/SuccessPay.vue"),
  },
  {
    path: "/pay-fail",
    name: "PayFail",
    component: () => import("@/views/Payment/FailPay/FailPay.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
