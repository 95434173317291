
import Vue from "vue";
import { mapActions, mapState } from "vuex";
import VoteTable from "@/components/Vote/VoteTable.vue";
import Advertising from "@/components/Advertising/Advertising.vue";
import { inputValidate } from "@/types/types";
import { Modules } from "@/store/state";
import { tableItem } from "@/components/Vote/VoteTable.vue";
import * as stateTypes from "@/store/types/stateTypes";

const TOPIC = "тема";
const SPEAKER = "спикер";
type typesTime = "минуты" | "часы" | "дни";
type voteData = {
  id: string;
  action: "vote" | "unvote";
};

export default Vue.extend({
  name: "VoteComponent",
  components: {
    VoteTable,
    Advertising,
  },
  data() {
    return {
      TOPIC: TOPIC,
      SPEAKER: SPEAKER,
      activeButton: TOPIC,
      modalSpeaker: false,
      speakerSending: false,
      modalTopic: false,
      topicSending: false,
      proposedSpeaker: {
        name: "",
        comment: "",
        errors: {},
      },
      proposedTopic: {
        name: "",
        description: "",
        errors: {},
      },
      updateComputedDate: 0,
      authErrorModal: {
        opened: false,
        text: "",
      },
      networks: [
        {
          network: "vk",
          image: require("../../assets/SocialIcons/vk.png"),
        },
        {
          network: "telegram",
          image: require("../../assets/SocialIcons/telegram.png"),
        },
        {
          network: "whatsapp",
          image: require("../../assets/SocialIcons/whatsapp.png"),
        },
        {
          network: "odnoklassniki",
          image: require("../../assets/SocialIcons/ok.png"),
        },
        {
          network: "viber",
          image: require("../../assets/SocialIcons/viber.png"),
        },
        {
          network: "skype",
          image: require("../../assets/SocialIcons/skype.png"),
        },
      ],
    };
  },
  computed: {
    ...mapState(["modules", "authed"]),
    modulePropose(): Modules["modulePropose"] {
      return this.modules.modulePropose;
    },
    formSpeaker(): inputValidate {
      return this.$refs.formSpeaker as inputValidate;
    },
    formTopic(): inputValidate {
      return this.$refs.formTopic as inputValidate;
    },
    timerText(): string {
      if (this.updateComputedDate) {
        /** updateComputedDate - переменная кототрая изменяется при окончании таймера */
      }
      const startOfVote = this.modulePropose.voteStart;
      return startOfVote > Date.now()
        ? "Голосование станет доступно через:"
        : "Голосование закончится через:";
    },
    voteAvailable(): boolean {
      if (this.updateComputedDate) {
        /** updateComputedDate - переменная кототрая изменяется при окончании таймера */
      }
      const startOfVote = this.modulePropose.voteStart;
      const endOfVote = this.modulePropose.voteEnd;
      const dateNow = Date.now();
      return endOfVote > dateNow && dateNow > startOfVote;
    },
    displayTimer(): boolean {
      const updateComputedDate = this.updateComputedDate;
      if (updateComputedDate) {
        /** updateComputedDate - переменная кототрая изменяется при окончании таймера */
      }
      const endOfVote = this.modulePropose.voteEnd;
      return endOfVote > Date.now();
    },
    proposeAvailable(): boolean {
      const startOfPropose = this.modulePropose.proposeStart;
      const endOfPropose = this.modulePropose.proposeEnd;
      const dateNow = Date.now();
      return endOfPropose > dateNow && dateNow > startOfPropose;
    },
    timeRemaining(): number {
      if (this.updateComputedDate) {
        /** updateComputedDate - переменная кототрая изменяется при окончании таймера */
      }
      const startOfCongress = this.modulePropose.voteStart;
      const endOfCongress = this.modulePropose.voteEnd;

      const dateNow = Date.now();
      if (startOfCongress > dateNow) {
        return startOfCongress - dateNow;
      }

      return endOfCongress - dateNow;
    },
    topics(): tableItem[] {
      const topics = this.modulePropose.themes.map((theme) => ({
        id: theme.id,
        text: theme.title,
        author: theme.fullName,
        voted: theme.voted === "1",
        votes: theme.votes,
        discount: 0,
      }));
      if (topics[0]) topics[0].discount = this.modulePropose.themeFirst;
      if (topics[1]) topics[1].discount = this.modulePropose.themeSecond;
      if (topics[2]) topics[2].discount = this.modulePropose.themeThird;

      return topics;
    },
    speakers(): tableItem[] {
      const speakers = this.modulePropose.speakers.map((speaker) => ({
        id: speaker.id,
        text: speaker.name,
        author: speaker.fullName,
        voted: speaker.voted === "1",
        votes: speaker.votes,
        discount: 0,
      }));
      if (speakers[0]) speakers[0].discount = this.modulePropose.speakerFirst;
      if (speakers[1]) speakers[1].discount = this.modulePropose.speakerSecond;
      if (speakers[2]) speakers[2].discount = this.modulePropose.speakerThird;

      return speakers;
    },
    backgroundTimer(): string {
      if (this.updateComputedDate) {
        /** updateComputedDate - переменная кототрая изменяется при окончании таймера */
      }
      const startOfVote = this.modulePropose.voteStart;
      if (Date.now() > startOfVote) {
        return "rgb(0 128 0 / 80%)";
      }

      return "linear-gradient(180deg, #013B69 0%, #3B3F96 100%)";
    },

    voteAdvertising(): stateTypes.Advertisement[] {
      return this.modules.moduleAdvertisement.first;
    },
    socialsText(): string {
      return `Я буду участвовать: "${this.modules.modulePropose.title}". Рекомендую принять участие!`;
    },
  },
  methods: {
    ...mapActions(["sendSpeaker", "sendTopic", "voteSpeaker", "voteTopic"]),
    async sendSpeakerHandler() {
      const valid = this.formSpeaker.validate();
      if (valid) {
        this.speakerSending = true;
        try {
          await this.sendSpeaker({
            name: this.proposedSpeaker.name,
            comment: this.proposedSpeaker.comment,
          });
          this.modalSpeaker = false;
          this.openAlert("Предложение успешно отправлено, спасибо за участие!");
          this.formSpeaker.resetValidation();
          this.proposedSpeaker.name = "";
          this.proposedSpeaker.comment = "";
        } catch (error) {
          this.proposedSpeaker.errors = this.validationErrorsHandler(error);
        } finally {
          this.speakerSending = false;
        }
      }
    },
    async sendTopicHandler() {
      const valid = this.formTopic.validate();
      if (valid) {
        this.topicSending = true;
        try {
          await this.sendTopic({
            title: this.proposedTopic.name,
            description: this.proposedTopic.description,
          });
          this.modalTopic = false;
          this.openAlert("Предложение успешно отправлено, спасибо за участие!");
          this.formTopic.resetValidation();
          this.proposedTopic.name = "";
          this.proposedTopic.description = "";
        } catch (error) {
          this.proposedTopic.errors = this.validationErrorsHandler(error);
        } finally {
          this.topicSending = false;
        }
      }
    },
    async voteTopicHandler(topic: voteData, callback: () => void) {
      if (!this.authCheck("Для голосования необходимо авторизоваться")) return;
      try {
        await this.voteTopic(topic);
      } finally {
        callback();
      }
    },
    async voteSpeakerHandler(speaker: voteData, callback: () => void) {
      if (!this.authCheck("Для голосования необходимо авторизоваться")) return;
      try {
        await this.voteSpeaker(speaker);
      } finally {
        callback();
      }
    },
    authCheck(errorText: string): boolean {
      if (!this.authed) {
        this.authErrorModal = {
          opened: true,
          text: errorText,
        };
      }
      return this.authed;
    },
    modalTopicOpen() {
      if (!this.authCheck("Чтобы предложить тему необходимо авторизоваться"))
        return;
      this.modalTopic = true;
    },
    modalSpeakerOpen() {
      if (!this.authCheck("Чтобы предложить спикера необходимо авторизоваться"))
        return;
      this.modalSpeaker = true;
    },
    getReadableTime(time: number) {
      return time < 10 ? "0" + time : time;
    },
    getReadableTimeText(time: number, type: typesTime) {
      const textFormsMinutes = ["минуту", "минуты", "минут"];
      const textFormsHours = ["час", "часа", "часов"];
      const textFormsDays = ["день", "дня", "дней"];

      let textForms: string[] = [];
      if (type === "минуты") {
        textForms = textFormsMinutes;
      }
      if (type === "часы") {
        textForms = textFormsHours;
      }
      if (type === "дни") {
        textForms = textFormsDays;
      }
      if (textForms.length === 0) {
        return "";
      }

      time = Math.abs(time) % 100;
      const time1 = time % 10;

      if (time > 10 && time < 20) {
        return textForms[2];
      }
      if (time1 > 1 && time1 < 5) {
        return textForms[1];
      }
      if (time1 == 1) {
        return textForms[0];
      }
      return textForms[2];
    },
    timerEnd() {
      this.updateComputedDate += 1;
    },
    goToAuthorization() {
      this.$router.push({ name: "Login" });
    },
    goToRegister() {
      this.smoothTo("register");
      this.authErrorModal.opened = false;
    },
  },
});
