
import Vue from "vue";

export default Vue.extend({
  name: "DefaultModal",
  props: {
    modal: {
      type: Boolean,
      required: true,
      default: false,
    },
    width: {
      type: String,
      default: "650",
    },
    height: {
      type: String,
      default: "320px",
    },
  },
  data() {
    return {
      modalKey: 0,
    };
  },
  methods: {
    modalClose() {
      this.$emit("close");
      this.$forceUpdate();
    },
  },
});
