
import Vue from "vue";
import { mapState } from "vuex";
import { Modules } from "@/store/state";
import * as stateTypes from "@/store/types/stateTypes";

type Social = stateTypes.Social & {
  active: boolean;
};

export default Vue.extend({
  name: "SocialIcons",
  computed: {
    ...mapState(["modules"]),
    moduleSocial(): Modules["moduleSocial"] {
      return this.modules.moduleSocial;
    },
    vk(): Social {
      const social = this.moduleSocial.socials.socials.find(
        (social) => social.name === "VK"
      );
      if (social) {
        return { active: true, link: social.link, name: social.name };
      }
      return { active: false, link: "", name: "" };
    },
    telegram(): Social {
      const social = this.moduleSocial.socials.socials.find(
        (social) => social.name === "Telegram"
      );
      if (social) {
        return { active: true, link: social.link, name: social.name };
      }
      return { active: false, link: "", name: "" };
    },
    whatsApp(): Social {
      const social = this.moduleSocial.socials.socials.find(
        (social) => social.name === "WhatsApp"
      );
      if (social) {
        return { active: true, link: social.link, name: social.name };
      }
      return { active: false, link: "", name: "" };
    },
    ok(): Social {
      const social = this.moduleSocial.socials.socials.find(
        (social) => social.name === "OK"
      );
      if (social) {
        return { active: true, link: social.link, name: social.name };
      }
      return { active: false, link: "", name: "" };
    },
    viber(): Social {
      const social = this.moduleSocial.socials.socials.find(
        (social) => social.name === "Viber"
      );
      if (social) {
        return { active: true, link: social.link, name: social.name };
      }
      return { active: false, link: "", name: "" };
    },
    skype(): Social {
      const social = this.moduleSocial.socials.socials.find(
        (social) => social.name === "Skype"
      );
      if (social) {
        return { active: true, link: social.link, name: social.name };
      }
      return { active: false, link: "", name: "" };
    },
    dzen(): Social {
      const social = this.moduleSocial.socials.socials.find(
        (social) => social.name === "Dzen"
      );
      if (social) {
        return { active: true, link: social.link, name: social.name };
      }
      return { active: false, link: "", name: "" };
    },
    site(): Social {
      const social = this.moduleSocial.socials.socials.find(
        (social) => social.name === "Site"
      );
      if (social) {
        return { active: true, link: social.link, name: social.name };
      }
      return { active: false, link: "", name: "" };
    },
  },
});
