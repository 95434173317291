
import { mapMutations, mapState } from "vuex";
import Vue from "vue";

export default Vue.extend({
  name: "AlertModal",
  computed: { ...mapState(["alertModal"]) },
  methods: {
    ...mapMutations(["closeAlert"]),

    close() {
      this.closeAlert();
    },
  },
});
