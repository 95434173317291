
import Vue from "vue";

type rules = "required";
type validateRulesField = (v: string, v2?: string) => string | boolean;
type validateRules = {
  [key in rules]: validateRulesField;
};

export default Vue.extend({
  name: "MainSelectInput",
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
    },
    items: {
      type: Array,
    },
    error: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    rules: {
      type: Array as unknown as () => rules[],
    },
  },
  data() {
    return {
      validateRules: {
        required: (v: string) => v !== "" || "Обязательное поле",
      } as validateRules,
    };
  },
  methods: {
    updateValue(val: Event): void {
      this.$emit("input", val ?? "");
    },
  },
  computed: {
    enableRules() {
      let rules: validateRulesField[] = [];
      Object.keys(this.validateRules).map((rule) => {
        if (this.rules !== undefined && this.rules.includes(rule as rules)) {
          rules.push(this.validateRules[rule as rules]);
        }
      });
      return rules;
    },
  },
});
