
import Vue from "vue";
import Title from "@/components/CommonComponents/Title/Title.vue";
import { mapState } from "vuex";
import { Reward } from "@/store/types/stateTypes";

export default Vue.extend({
  name: "RewardsComponent",
  components: { Title },
  data() {
    return {
      modalAward: {
        show: false,
        award: {} as Reward,
      },
      settings: {
        centerMode: true,
        centerPadding: "20px",
        focusOnSelect: true,
        infinite: true,
        slidesToShow: 2,
        speed: 500,
        swipeToSlide: true,
        initialSlide: 2,
      },
    };
  },
  computed: {
    ...mapState(["modules", "window"]),
    title(): string {
      return this.modules.moduleRewards.title;
    },
    rewards(): Reward[][] {
      return this.modules.moduleRewards.rewardList.filter(
        (list: Reward[]) => list.length > 0
      );
    },
  },
  methods: {
    showAwardModal(award: Reward) {
      this.modalAward = {
        show: true,
        award: award,
      };
    },
    getDate(date: string): string {
      return new Date(+date * 1000).toLocaleString("ru", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
    getDateForModal(date: string): string {
      return new Date(+date * 1000).toLocaleString("ru", {
        day: "numeric",
        month: "numeric",
        year: "numeric",
      });
    },
  },
});
