
import Vue from "vue";
import { mapMutations, mapState } from "vuex";
import { inputValidate } from "@/types/types";

export default Vue.extend({
  name: "PersonalCabinetModal",
  computed: {
    ...mapState(["modalPersonalCabinet"]),
    formCabinet(): inputValidate {
      return this.$refs.formCabinet as inputValidate;
    },
  },
  methods: {
    ...mapMutations(["changeModalPersonalCabinet"]),
    authorization() {
      const valid = this.formCabinet.validate();
      if (valid) {
        const emptyData = {
          opened: false,
          email: "",
          password: "",
        };
        this.changeModalPersonalCabinet(emptyData);
        this.formCabinet.resetValidation();
      }
    },
  },
});
