
import Vue from "vue";
import { mapState } from "vuex";
import * as stateTypes from "@/store/types/stateTypes";
import Title from "@/components/CommonComponents/Title/Title.vue";

export default Vue.extend({
  name: "SponsorModule",
  components: { Title },
  computed: {
    ...mapState(["modules"]),
    title(): string {
      return this.modules.moduleSponsor.title;
    },
    sponsorsLevelOne(): stateTypes.Sponsor[] {
      return this.modules.moduleSponsor.sponsors.filter(
        (sponsor: stateTypes.Sponsor) => sponsor.level === 1
      );
    },
    sponsorsLevelSecond(): stateTypes.Sponsor[] {
      return this.modules.moduleSponsor.sponsors.filter(
        (sponsor: stateTypes.Sponsor) => sponsor.level === 2
      );
    },
    sponsorsLevelThird(): stateTypes.Sponsor[] {
      return this.modules.moduleSponsor.sponsors.filter(
        (sponsor: stateTypes.Sponsor) => sponsor.level === 3
      );
    },
    sponsorsLevelThirdTitle(): string {
      return this.modules.moduleSponsor.sponsorLevels[0] ?? "";
    },
    sponsorsLevelFourth(): stateTypes.Sponsor[] {
      return this.modules.moduleSponsor.sponsors.filter(
        (sponsor: stateTypes.Sponsor) => sponsor.level === 4
      );
    },
    sponsorsLevelFourthTitle(): string {
      return this.modules.moduleSponsor.sponsorLevels[1] ?? "";
    },
    responsiveLevelSecond() {
      return [
        {
          breakpoint: 1264,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 1,
          },
        },
      ];
    },
    responsiveLevelMore() {
      return [
        {
          breakpoint: 1264,
          settings: {
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            dots: true,
            arrows: false,
            slidesToShow: 2,
          },
        },
      ];
    },
    sponsorsLevelSecondSlides(): number {
      const length = this.sponsorsLevelSecond.length;
      return length < 3 ? length : 3;
    },
    sponsorsLevelThirdSlides(): number {
      const length = this.sponsorsLevelThird.length;
      return length < 6 ? length : 6;
    },
    sponsorsLevelFourthSlides(): number {
      const length = this.sponsorsLevelFourth.length;
      return length < 6 ? length : 6;
    },
  },
});
