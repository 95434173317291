
import Vue from "vue";
import { mapState } from "vuex";
import { TeamCategory } from "@/store/types/stateTypes";
import Title from "@/components/CommonComponents/Title/Title.vue";
import TeamMember from "@/components/Team/TeamMember/TeamMember.vue";
import VideoLink from "@/components/CommonComponents/VideoLink/VideoLink.vue";

export default Vue.extend({
  name: "TeamComponent",
  components: { Title, TeamMember, VideoLink },
  data() {
    return {
      modal: {
        show: false,
        item: {} as TeamCategory,
      },
      showAllDescription: false,
    };
  },
  computed: {
    ...mapState(["modules"]),
    teamList(): TeamCategory[][] {
      return this.modules.moduleTeam.members;
    },
    title(): string {
      return this.modules.moduleTeam.title;
    },
  },
  methods: {
    showModal(item: TeamCategory) {
      this.modal = {
        show: true,
        item: item,
      };
    },
  },
});
